var datas = [{
  zhTit: '华侨城湿地',
  enTit: 'OCT Wetland',
  cnt1: '原始海岸线，自然学校，红树林',
  cnt2: '位于深圳市腹地，是深圳的“城市之肾”。你可以感受湿地丰富的物种，了解湿地的保护。这里拥有原始红树林，吸引了上百种鸟类，春冬候鸟与你相约湿地，夏季探秘红树林，秋季来一场自然创作之旅！',
  cnt3: '深圳市南山区白石路与云天路交界处'
},
{
  zhTit: '深圳湾公园 ',
  enTit: 'Shenzhen Bay Park',
  cnt1: '候鸟，弹涂鱼，海景',
  cnt2: '深圳湾公园以“海岸卫士”红树林和世界级的候鸟栖息地闻名。你可以和成群候鸟相会，偶遇二级保护鸟类黑脸琵鹭；也可以尽看红树林里的潮涨潮落，感受潮间带生物的家园；抑或在多层滨水慢道上散步骑行，于城市和自然之间自由徜徉。',
  cnt3: '深圳市南山区滨海大道深圳湾公园地铁站D2出口'
},
{
  zhTit: '福田红树林生态公园',
  enTit: 'Futian Mangrove Ecological Park',
  cnt1: '生态修复，自然力乐园，生态课',
  cnt2: '从废弃的码头改建而来，是全国第一例由民间公益机构托管和运营的湿地公园。靠近河流出海口，鱼虾蟹丰富，可以看到会爬树的弹涂鱼和珍稀的黑脸琵鹭。园内有中美联合打造的“自然力乐园”，让宝宝安全走进大自然。',
  cnt3: '深圳市福田区福荣路和新洲路交汇处'
},
{
  zhTit: '塘朗山郊野公园',
  enTit: 'Tanglang Mountain Park',
  cnt1: '豹猫，蝴蝶，山野徒步',
  cnt2: '塘朗山郊野公园以山林为主，你能发现国家二级保护植物土沉香、桫椤，也能找到野生哺乳动物的踪迹，例如豹猫、小灵猫、野猪、果子狸等。多条自然步道交通便利，适合登高俯瞰，观赏落日霞光。',
  cnt3: '深圳市福田区龙珠六路51号'
},
{
  zhTit: '香蜜公园',
  enTit: 'Honey Park',
  cnt1: '海绵城市，荔枝林，生态水系',
  cnt2: '香蜜公园融入海绵城市的理念，园内终年繁花满地，花香四溢。你可以在半空的栈道俯瞰脚下10万平方米的荔枝林，也可以在湖边绿地观察水生植物和昆虫，鸟叫虫鸣唤起你的童年乐趣。',
  cnt3: '深圳市福田区农园路30号'
},
{
  zhTit: '冈厦1980绿色屋顶',
  enTit: 'Gangxia 1980',
  cnt1: '城中村，海绵城市，绿色屋顶',
  cnt2: '城中村农民房改造的日式青年公寓。钢结构屋顶立体空间承载着400多个具有雨水吸纳功能的种植箱，扩展了屋顶绿化空间，还为公寓居民新增了休闲花园，提升了社区环境的宜居性。每周开放时间为周二与周四（14:00-16:00）。',
  cnt3: '深圳市福田区岗厦村东三坊204号'
},
{
  zhTit: '洪湖公园',
  enTit: 'Honghu Park',
  cnt1: '荷花，水鸟，落羽杉',
  cnt2: '洪湖公园夏有荷花，冬有落羽杉。6月的荷花展上，12万平方米的荷花竞相争艳。石桥水榭让你置身碧波绿叶之中，感受幽香沁脾；这里也是水鸟的天堂，可以和水鸟来一场亲密接触。',
  cnt3: '深圳市罗湖区文锦北路2023号'
},
{
  zhTit: '仙湖植物园',
  enTit: 'Fairy Lake Botanical Garden',
  cnt1: '植物诺亚方舟，苏铁，木兰，秋海棠',
  cnt2: '仙湖植物园是植物科学研究基地。近1.2万种植物营造出天然氧吧，你可以看到国家一级保护植物桫椤、金茶花等；也可以参观植物专类园，在苏铁、荫生植物和沙漠植物间穿越；还可以在自然的王国里观花赏鸟，与蝶共舞。',
  cnt3: '深圳市罗湖区莲塘仙湖路160号'
},
{
  zhTit: '梧桐山',
  enTit: 'Wutong Mountain',
  cnt1: '毛棉杜鹃花，溯溪，两爬动物',
  cnt2: '梧桐山是深圳最高峰。山路途径溪流、瀑布，山中生物丰富多样，夏季在溪涧穿梭，有蝴蝶、蜻蜓、豆娘，水中有原生鱼类、香港瘰螈、蛙鸣萤光，爱户外探险的你不容错过。春季漫山的毛棉杜鹃花灿若云霄，走进山中让人幸福满怀。',
  cnt3: '深圳市茂仔村137号梧桐山景区西北门'
},
{
  zhTit: '杨梅坑',
  enTit: 'Yangmeikeng',
  cnt1: '潮间带，寄居蟹，沿海公路',
  cnt2: '杨梅坑的海岸线享誉深圳，沿海公路移步换景，是单车骑行的极佳目的地。丰富的潮间带可以观察海洋生物，如沙虫、寄居蟹、海葵等。杨梅坑也有可登山溯溪的小径，可以品尝农家新鲜海味的餐厅。',
  cnt3: '深圳市龙岗区杨梅坑新东路海滨浴场旁'
},
{
  zhTit: '七娘山',
  enTit: 'Qiniang Mountain',
  cnt1: '国家地质公园，地质博物馆，吊钟花',
  cnt2: '七娘山是古火山喷发作用形成，岩石类型多样。你可以来探寻古火山的遗迹，也可以参观深圳唯一的地质博物馆。这里垂直分布的森林生态系统完整丰富、野趣十足。不妨和家人朋友相约徒步登山。',
  cnt3: '深圳市大鹏新区地质公园路1号'
},
{
  zhTit: '东西冲海岸线',
  enTit: 'Dongxichong Coastline',
  cnt1: '徒步海岸线，滨海植物，回收海漂垃圾',
  cnt2: '入选《国家地理杂志》中国十大最美丽的徒步路线之一。除了浪花沙滩，还有独特的礁石海岸线，沿途可见露兜树、草海桐等滨海植物；西涌天文台还可以让你瞭望浩瀚星空。',
  cnt3: '深圳市龙岗区东涌旅游区内东涌沙滩'
}
]

var datasen = [{
  zhTit: '华侨城湿地',
  enTit: 'OCT Wetland',
  cnt1: 'Seashore, Nature School, Mangroves',
  cnt2: 'Located in the city, OCT wetland is the green lung of Shenzhen. Come explore the diverse species here and learn wetland protection; visit mangroves and catch a glimpse of hundreds of migratory birds in spring, venture into the mangroves in summer, and join handwork classes using leaves in autumn.',
  cnt3: 'Near the junction of Baishi Road and Yuntian, Nanshan District, Shenzhen'
},
{
  zhTit: '深圳湾公园',
  enTit: 'Shenzhen Bay Park',
  cnt1: 'Migratory birds, Mudskipper, Sea view',
  cnt2: "Shenzhen Bay Park is known as a habitat for migratory birds and for its mangroves. Here you will see thousands of migratory birds, including black-faced spoonbill. You can see creatures in tidal zone.You can also walk or bike on the multiple passages along the shore.",
  cnt3: 'Exit D2, Shenzhen Bay Station, Binhai Boulevard, Nanshan District, Shenzhen'
},
{
  zhTit: '福田红树林生态公园',
  enTit: 'Futian Mangrove Ecological Park',
  cnt1: 'Ecological restoration, Outdoor Classroom, Ecological classes',
  cnt2: 'Converted from an abandoned dock, Futian Mangrove Ecological Park is the first city park run by a NPO in China. The bordering estuary provides habitat for fish, shrimp and crab; In this area, you can find mudskipper and black-faced spoonbill. The Outdoor Classroom co-built by Nature Explore Classrooms is an ideal place for pre-school kids to have fun while learning about nature.',
  cnt3: 'Near the junction of Furong Road and Xinzhou Road, Futian district, Shenzhen.'
},
{
  zhTit: '塘朗山郊野公园',
  enTit: 'Tanglang Mountain Park',
  cnt1: 'Asian leopard cat, Butterflies, Trekking',
  cnt2: 'Tanglang Mountain is covered by greens, where you can find rare plants such as Alsophila spinulosa, Aquilaria sinensis, as well as traces of wild mammals such as Asian leopard cat, small Indian civet, and masked palm civet. Several trails will lead you to the peak where you can enjoy a splendid sunset.',
  cnt3: '51th, Longzhuliu Road, Futian District, Shenzhen'
},
{
  zhTit: '香蜜公园',
  enTit: 'Honey Park',
  cnt1: 'Sponge city, Lychee orchard, Ecological water system',
  cnt2: "Honey Park is an example of sponge city with an ecological water system. It is garnished with flowers throughout the year. You can enjoy the view of a 100,000 square meters' lychee orchard or observe aquatic plants and insects by the lake, bringing you fun experiences of childhood.",
  cnt3: '30th, Nongyuan road, Futian District, Shenzhen'
},
{
  zhTit: '冈厦1980绿色屋顶',
  enTit: 'Gangxia 1980',
  cnt1: 'Urban village, Sponge city, Green roof',
  cnt2: 'Gangxia 1980 is a Japanese style youth apartment complex transformed from an old village house. The metal structure on the roof contains 400 planter boxes which collect rainwater. The rooftop serves as a green space for the building\'s residents while also improving the overall environment of the neighborhood. It opens on Tuesday and Thursday from 14:00-16:00.',
  cnt3: '204th, Dongsanfang, Gangxia Village, Futian District, Shenzhen'
},
{
  zhTit: '洪湖公园',
  enTit: 'Honghu Park',
  cnt1: 'Lotus,Waterbirds, Bald cypress',
  cnt2: 'Known for lotus in summer and bald cypress in winter, Honghu Park hosts a big lotus festival each June with 120,000 square meters of lotus. Walking by the lakeside, you can immerse yourself in the lotus flagrance. It is also a paradise for waterbirds, where you may have up-close experience with them.',
  cnt3: '2023th, Wenjingbei Road, Luohu District, Shenzhen'
},
{
  zhTit: '仙湖植物园',
  enTit: 'Fairy Lake Botanical Garden',
  cnt1: 'Plant Ark, Cycads, Magnolia, Begonia',
  cnt2: "Fairy Lake Botanical Garden is a scientific research site. This natural oxygen bar comprises of nearly 12,000 species of plants. You can find China's rare plants like Alsophila spinulosa, Camellia nitidissima, etc. You can meander through cycads, shade plants, cactus and succulent plants. Besides, you can enjoy birds, flowers and butterflies in the kingdom of plants .",
  cnt3: '160th, Liantang Xianhu Road, Luohu District, Shenzhen'
},
{
  zhTit: '梧桐山',
  enTit: 'Wutong Mountain',
  cnt1: 'Rhododendron moulmainense, Streams, Amphibians',
  cnt2: 'The tallest mountain in Shenzhen, Wutong Mountain greets you with stream, waterfalls, and abundant green. Trekking along the stream, you will meet butterflies, dragonflies, and damselflies in the air, and Hong Kong newt and frogs in the water; in spring, the blooming Rhododendron moulmainense flowers all over the mountain will fill you with joy.',
  cnt3: '137th, Maozi Village, Shenzhen'
},
{
  zhTit: '杨梅坑',
  enTit: 'Yangmeikeng',
  cnt1: 'Intertidal zone, Hermit crabs, Coast road',
  cnt2: 'Yangmeikeng coastline is of dazzling beauty, biking along it will bring you radiant views. The intertidal zone is habitat to creatures such as sandworms, hermit crabs and sea anemones. You can also trek along the stream and taste fresh seafood here.',
  cnt3: 'Near Daya Bay, Dakeng Village, Longgang District, Shenzhen'
},
{
  zhTit: '七娘山',
  enTit: 'Qiniang Mountain',
  cnt1: 'Geopark, Geological museum, Fuchsia hybrida',
  cnt2: 'Qiniang Mountain, formed by volcanic eruption, contains a variety of rock types. You could explore remains of ancient volcanoes, as well as visit the only geographic museum in Shenzhen.  The verdant forest nurtures a rich eco-system which makes it perfect for excursions.',
  cnt3: '1st, Dizhigongyuan Road, Dapeng New District, Shenzhen'
},
{
  zhTit: '东西冲海岸线',
  enTit: 'Dongxichong Coastline',
  cnt1: 'Coast trail, Seashore plants, Ocean waste cleanup',
  cnt2: 'It is listed as one of the ten most beautiful hiking trails in China by the National Geographic. Besides pristine water and soft beach, you will see rugged rocks and seaside trees. You can also look into stars in the Observatory of Xichong.',
  cnt3: "Dongchong scenic area, Nan'ao Town, Longgang District, Shenzhen"
}
]

export {
  datas,
  datasen
}
