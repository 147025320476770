<template>
    <div class="CityB3">
        <el-container>
            <el-header style="padding: 0" height="100%">
              <vheader class="hidden-sm-and-down"></vheader>
              <ivheadera class="hidden-md-and-up"></ivheadera>
                <div class="top-img">
                  <!-- <img class="hidden-sm-and-down" width="100%" src="../../assets/images/B/B3/topimg.jpg" alt=""> -->
                  <img class="hidden-md-and-up" width="100%" src="../../assets/images/B/B3/topimg-m.jpg" style="display: block;" alt="" />
                  <div class="top-img-top">
                      <p>JUST SHENZHEN</p>
                      <span>正深圳文创路线</span>
                    </div>
                </div>
            </el-header>
            <el-main style="margin-top: 0" class="asaa">
              <div class="w asaa-mia">
                <div class="ec-top-foots hidden-sm-and-down">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item > <router-link to="/JustSZ">体验深圳</router-link></el-breadcrumb-item>
                    <el-breadcrumb-item>正深圳文创路线</el-breadcrumb-item>
                  </el-breadcrumb>
                </div>
                <div class="introduce">
                  <el-row type="flex" style="align-items: flex-end;flex-wrap: wrap">
                    <el-col :xs="24" :sm="24" :md="12">
                     <video src="../../assets/images/B/B3/top-video.mp4" poster="../../assets/images/B/B3/video-img.png" controls style="width: 100%;"></video>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12">
                      <div class="txt-box">
                        <img width="54%" src="../../assets/images/B/B3/title.png" alt="">
                        <div class="txt">
                          <p>继2019年3月“正深圳”第一条线路即全域体验线路发布以来，我们希望能够通过深挖一系列专题，为所有居住在这座城市以及对这里好奇的中外人士展现一个更生动更全面更有趣的深圳。在这一过程中，文化创意产业成为最先吸引我们目光的一个领域。
                          </p>
                          <p>事实上，除了科技创新和经济发展之外，深圳正在以“设计之都”和欣欣向荣的文创产业而逐渐被市民、侨胞和外国友人所熟知和了解。
                          </p>
                          <p>通过整合信息和资源，立足公共服务与公共信息的设计初衷，我们为公众设计并提供这样一条中英双语的文创主题线路，希望能够引导大众亲身参与并了解文创行业的蓬勃发展、获取源源不断的乐趣与灵感，从而进一步提升中外居民对于城市的认同感、归属感，并助力深圳文创行业拥有更强更广的影响力与号召力。
                          </p>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="map">
                  <img width="100%" src="../../assets/images/B/B3/map.jpg" alt="">
                  <div class="map-cnts">
                    <el-row type="flex">
                      <el-col :xs="24" :sm="24" :md="12" class="left">
                        <div class="cnts">
                          <p class="hidden-sm-and-down">JUST</p>
                          <p class="hidden-sm-and-down">SHENZHEN</p>
                          <p class="cnts-m hidden-md-and-up">正深圳文创路线图</p>
                        </div>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="12" class="right">
                        <div class="cnts">
                          <a href="/static/download/civilization.jpg" class="dw-btn" target="_blank">下载地图</a>
                          <p class="hidden-sm-and-down">正深圳文创路线图</p>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div class="show-box">
                  <div class="card" v-for="(item,index) in cardData" :key="index">
                    <el-col :xs="24" :sm="12" :md="8">
                      <div class="logo-img">
                        <img width="100%" :src="item.logo" alt="">
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="16">
                      <div class="content">
                        <div class="top">
                          <div>
                            <div class="num">{{index+1}}</div>
                          </div>
                          <div class="title">
                            <div class="en">{{item.enTitle}}</div>
                            <div class="zh">{{item.title}}</div>
                          </div>
                        </div>
                        <div class="base">
                          <el-col :xs="24" :sm="24" :md="12">
                            <div class="left">
                              <div class="txt">
                                {{item.content}}
                              </div>
                              <div class="item">
                                <div class="logo">
                                  <img src="../../assets/images/B/B3/address.png" alt="">
                                </div>
                                <div class="txt">
                                  <p>{{item.address}}</p>
                                </div>
                              </div>
                              <div class="item" v-show="item.metro!=''">
                                <div class="logo">
                                  <img src="../../assets/images/B/B3/metro.png" alt="">
                                </div>
                                <div class="txt">
                                  <p>{{item.metro}}</p>
                                </div>
                              </div>
                              <div class="item">
                                <div class="logo">
                                  <img src="../../assets/images/B/B3/bus.png" alt="">
                                </div>
                                <div class="txt">
                                  <p>{{item.bus}}</p>
                                </div>
                              </div>
                            </div>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="12">
                            <div class="right">
                              <div class="item">
                                <div class="logo">
                                  <img src="../../assets/images/B/B3/foods.png" alt="">
                                </div>
                                <div class="txt" v-html="item.foods">
                                </div>
                              </div>
                              <div class="item">
                                <div class="logo">
                                  <img src="../../assets/images/B/B3/special.png" alt="">
                                </div>
                                <div class="txt" v-html="item.specail">
                                </div>
                              </div>
                            </div>
                          </el-col>
                        </div>
                      </div>
                    </el-col>
                  </div>
                </div>
              </div>
              <div class="base-download">
                <div class="w">
                  <div class="download-box">
                    <el-col :xs="24" :sm="24" :md="12">
                      <div class="img-box">
                        <img src="../../assets/images/B/B3/map-download01.jpg" alt="">
                        <img src="../../assets/images/B/B3/map-download02.jpg" alt="">
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12">
                      <div class="notes-content">
                        <img src="../../assets/images/B/B3/title02.png" alt="">
                        <div class="txt">种种精彩，都可以在“正深圳·文创线路”地图中轻松获取！
                        </div>
                        <div class="button" @click="linkBtn">
                          下载地图
                        </div>
                      </div>
                    </el-col>
                  </div>
                </div>
              </div>
            </el-main>
            <el-footer>
              <vfooter class="hidden-sm-and-down"></vfooter>
              <ivfooter class="hidden-md-and-up"></ivfooter>
            </el-footer>
        </el-container>
    </div>
</template>
<script>
export default {
  data () {
    return {
      cardData: [
        {
          logo: require('../../assets/images/B/B3/logo01.png'),
          title: '海上世界文化艺术中心',
          enTitle: 'Sea World Culture and Arts Center',
          address: '深圳市南山区蛇口望海路1187号',
          metro: '海上世界地铁站A出口，朝邮轮方向行走5分钟',
          bus: '海上世界南公交站，22路、79路、80路',
          foods: '<p>雨花西餐厅：蛇口太子路海上世界海滨商业中心2层</p><p>龙酒场日本料理：深圳市南山区兴华路9号</p><p>明华轮四号甲板餐厅：深圳市南山区蛇口工业区太子路海上世界明华轮四层海上世界F1</p>',
          specail: '<p>女娲补天石像：蛇口精神</p><p>海上世界：蛇口地标</p><p>滨海步道：最美观日地点</p>',
          content: '海上世界文化艺术中心位于深圳外籍人士最密集的蛇口海上世界片区，内含多个高品质文化艺术展馆，欲打造为向世界传播文化艺术信息的高地。'
        },
        {
          logo: require('../../assets/images/B/B3/logo02.png'),
          title: 'G&G创意社区',
          enTitle: 'G&G Creative Park',
          address: '深圳市南山区荔园路9号g38',
          metro: '2号线水湾地铁站，向北行走5分钟',
          bus: '南玻大厦公交站，m409路',
          foods: '<p>G&G社区西面美食空间：深圳市南山区荔园路9号</p><p>B+cafe：深圳市南山区南海大道1057号科技大厦</p><p>华洋酒楼：深圳市南山区水湾路14号华洋宾馆首层</p>',
          specail: '<p>G&G创意社区：美食空间和集装箱文创特色商店 </p><p>圈创101：美食空间，不定期开展音乐活动</p>',
          content: 'G&G创意社区是聚焦新生活方式的设计体验中心与产业创意中心，是共享经济下的创意目的地和内容发生地，创造出了许多文艺商业新物种，包括：好吃集了、ING创空间、有趣街、芒草节、创意论坛等等。'
        },
        {
          logo: require('../../assets/images/B/B3/logo03.png'),
          title: '华•美术馆',
          enTitle: 'OCT Art and Design Gallery',
          address: '深圳市南山区深南大道9009号',
          metro: '1号线华侨城地铁站C出口',
          bus: '何香凝美术馆公交站，21路、26路、32路、101路、113路、204路、222路、223路、234路、338路、369路、m123路、m132路、m183路、m398路、m433路、m487路、m488路、n4路、n6路',
          foods: '<p>华膳中餐厅：深南大道9009号深圳华侨城洲际大酒店L楼</p><p>红砖房乡村餐厅：深圳市南山区华侨城海景美食街102号</p><p>庭悦（浙江菜）：深圳市南山区华侨城兴隆街2号</p>',
          specail: '<p> 何香凝美术馆：所在地区文化地标</p><p>华美术馆：所在地区文化地标</p>',
          content: '华•美术馆是国内首家以设计为主题的美术馆，外形酷似“蜂巢”，与何香凝美术馆以及OCT当代艺术中心共同构成华侨城的“艺术三角”。场馆还特设艺术沙龙，为艺术爱好者提供全方位的交流平台。'
        },
        {
          logo: require('../../assets/images/B/B3/logo04.png'),
          title: '何香凝美术馆',
          enTitle: 'Hexiangning Art Museum',
          address: '深圳市南山区深南大道9013号',
          metro: '1号线华侨城地铁站C出口',
          bus: '何香凝美术馆公交站，21路、26路、32路、101路、113路、204路、222路、223路、234路、338路、369路、m123路、m132路、m183路、m398路、m433路、m487路、m488路、n4路、n6路',
          foods: '<p>华膳中餐厅：深南大道9009号深圳华侨城洲际大酒店L楼</p><p>红砖房乡村餐厅：深圳市南山区华侨城海景美食街102号</p><p>庭悦（浙江菜）：深圳市南山区华侨城兴隆街2号</p>',
          specail: '<p> 何香凝美术馆：所在地区文化地标</p><p>华美术馆：所在地区文化地标</p>',
          content: '位于中国深圳华侨城内，1997年4月18日建成开馆。江泽民题写馆名，是中国第一个以个人名字命名的国家级美术馆，在中国艺术界、学术界和社会上产生了较强的影响力，并逐渐将其影响力辐射至国际。'
        },
        {
          logo: require('../../assets/images/B/B3/logo05.png'),
          title: '万象天地',
          enTitle: 'Mixc World',
          address: '深圳市南山区深南大道与沙河西路交界',
          metro: '1号线高新园站A出口',
          bus: '大冲（2）公交站，19路、21路、42路、45路、70路、72路、79路、101路、113路、204路、223路、234路、b683路、m109路、m109路、m388路、m398路、m448路',
          foods: '<p>陈鹏鹏鹅肉饭店：深圳市南山区华润万象天地B134号 </p><p>在榕树下餐厅：深圳市南山区华润万象天地SL186</p><p>杏花堂山西菜：深圳市南山区华润万象天地B134号</p>',
          specail: '<p>大象艺术装置“Bubblecoat Elephant”：地区艺术装置代表</p><p>SeeSaw Coffee：网红咖啡 </p><p>诚品生活：特色书店+文创周边产品</p><p>各种潮牌商店</p>',
          content: '位于深圳南山区，集cafe、美食、购物商场于一身，融入创新、时尚、人文、艺术、社交元素，打造“全新的漫步式城市创作空间”，是深圳网红打卡拍照的圣地之一。'
        },
        {
          logo: require('../../assets/images/B/B3/logo06.png'),
          title: '高北十六创意园',
          enTitle: 'Gaobei 16 Creative Park "Block Sixteen"',
          address: '深圳科技园高新北六道16号',
          metro: '深大地铁站，出站向北行走1.5公里；高新园地铁站，B出口',
          bus: '科苑北环立交（2），36路、81路、236路、316路、334路、339路、b607路、e9路、m109路、m243路、m299路、m429路、m474路、m539路',
          foods: '<p> 园区内的“香遇”音乐火锅店、“甘其食”包子店、“觅糖”下午茶、“喜释手工吐司”、“三兽”面包和茶、“串嘴”川菜小食。</p>',
          specail: '<p>高北十六集装箱：网红打卡拍照</p><p>创意空间：不定期开展文创活动</p>',
          content: '高北十六创意园地处深圳科技园高新北六道16号，故名高北十六创意园。园区以文化、创意和科技产业为主，探索并打造未来生活方式原型街区。园区内经常会有音乐会、论坛、读书会等文娱活动。'
        },
        {
          logo: require('../../assets/images/B/B3/logo07.png'),
          title: '华侨城创意文化园',
          enTitle: 'OCT LOFT',
          address: '深圳市南山区锦绣北街2号',
          metro: '侨城东地铁站A出口',
          bus: '侨城东地铁站公交站，21路、26路、32路、79路、338路、369路、m123路、m132路、m398路、m487路、m488路',
          foods: '<p> 驴甜品：深圳市南山区华侨城创意文化园恩平街1栋1D  </p><p> My noodle：深圳市南山区华侨城创意文化园F1栋105A</p><p>一粥：深圳市南山区华侨城LOFT广场创意文化园F3栋</p>',
          specail: '<p>旧天堂书店：在地文化空间代表</p><p>B10现场：文艺活动</p><p>T街：文创产品的集中展示消费地</p>',
          content: '华侨城创意文化园得名于由厂房而来的这种改造模式。园区融合了“创意、设计、艺术”多元领域，正逐渐形成深圳的地标性建筑和新型的文化产业开放社区，是深圳文艺青年和文艺活动的聚集地。'
        },
        {
          logo: require('../../assets/images/B/B3/logo08.png'),
          title: '深圳当代艺术与城市规划馆',
          enTitle: 'Shenzhen Museum of Contemporary Art and Urban Planning',
          address: '深圳市福田区莲花街道福中路184号',
          metro: '',
          bus: '市民中心公交站，38路、60路、107路、236路、398路、e28路、m123路、m262路、m390路、n9路、高快巴士28路',
          foods: '<p>云雕塑咖啡厅：当代艺术馆与城市规划展览馆云雕塑内 </p><p>大灰狼餐厅：福田区福中一路中心书城南区1楼5号电梯口</p><p>J-HOUSE：福田区福中一路2014号中心书城1楼南区</p>',
          specail: '<p>关山月美术馆：所在地区文化地标</p><p>深圳市当代艺术与城市规划馆：所在地区文化地标</p><p>深圳音乐厅和中心书城：所在地区文化地标</p><p>莲花山：俯瞰福田区的佳地、定期举办草地音乐节</p><p>深圳博物馆：了解深圳发展历史</p>',
          content: '深圳市当代艺术与城市规划馆是由“解构主义急先锋”——Coop Himmelblau设计。当代艺术博物馆和城市规划馆在同一个建筑表皮之下，分别作为文化交流之处以及建筑展览场所，是深圳市福田区文化艺术的新地标。'
        },
        {
          logo: require('../../assets/images/B/B3/logo09.png'),
          title: '深业上城',
          enTitle: 'Shum Yip Upperhills',
          address: '深圳市福田区中心区北皇岗路与笋岗西路交汇处西北侧',
          metro: '3号线莲花村站,出站向北行走1.5公里',
          bus: '莲花一村公交车站，11路、12路、58路、79路、102路、104路、213路、323路、363路、365路、398路、m169路、m391路、m392路、m454路、m500路、m511路',
          foods: '<p>吴系茶餐厅：福田区深业上城小镇L3层T3083铺</p><p>京盛宇品茶店：福田区深业上城L3层T3075号商铺</p><p>陇熙火锅：福田区深业上城商业综合楼9栋T3091</p>',
          specail: '<p>特色小镇：含有本来书店、Wegallery等文创店</p><p>CEEC：高新科技展示消费场所</p>',
          content: '位于福田中心区，坐拥三大中央公园，通过景观连廊连接莲花山与笔架山。专注于通过创意设计、产品创新和品质塑造，引领“尚上生活”。深业上城的三楼LOFT小镇是网红拍照打卡的圣地。'
        },
        {
          logo: require('../../assets/images/B/B3/logo11.png'),
          title: '关山月美术馆',
          enTitle: 'Guan Shanyue Art Museum',
          address: '深圳市福田区红荔路6026号',
          metro: '少年宫站F2出入口下，往东走约370米',
          bus: '关山月美术馆（1）公交站，34路、322路、398路、m105路、m173路、m183路、m262路、m459路、m488路、m511路、高峰专线123路、高速巴士18路关山月美术馆（2）公交站，10路、14路、64路、108路、111路、215路、m555大站快车、m555路',
          foods: '<p>天籽·蔬食餐厅：福田区关山月美术馆一楼</p><p>观舍视觉艺术餐厅：福田区关山月美术馆二楼</p><p>J-HOUSE：福田区福中一路2014号中心书城1楼南区</p>',
          specail: '<p>关山月美术馆：所在地区文化地标</p><p>深圳市当代艺术与城市规划馆：所在地区文化地标 </p><p>深圳音乐厅和中心书城：所在地区文化地标</p><p>莲花山：俯瞰福田区的佳地、定期举办草地音乐节</p><p>深圳博物馆：了解深圳发展历史</p>',
          content: '关山月美术馆是以中国著名画家关山月先生名字命名的国家公益性文化事业机构。作为国际艺术交流和对话的重要平台，这里先后举办了近百场国际艺术交流展览。'
        },
        {
          logo: require('../../assets/images/B/B3/logo11.png'),
          title: '深圳大剧院',
          enTitle: 'Shenzhen Grand Theater',
          address: '深圳市罗湖区深南东路5018号',
          metro: '1号线大剧院地铁站',
          bus: '大剧院公交站，7路、23路、62路、63路、377路、m152路、m360路',
          foods: '<p>源烧酒场：罗湖区深南东路5016号KKMALL京基·百纳空间F1</p><p>喜面堂：罗湖区深南东路5016号KKMALL京基·百纳空间F1</p><p>79号渔船海鲜主题饭店：深圳市罗湖区红岭中路1002-2</p>',
          specail: '<p>大剧院：所在地区文化地标</p><p>京基100：深圳第二高楼</p><p>Kkmall：休闲娱乐购物</p>',
          content: '深圳大剧院建于1984年，作为中国第一个引进“品”字形舞台和国外的先进机械设备的建筑，成为中国现代剧场建筑进入下一个阶段的标志，拉开了“大剧院时代”的序幕。'
        },
        {
          logo: require('../../assets/images/B/B3/logo12.png'),
          title: '大芬油画村',
          enTitle: 'Dafen Oil Painting Village',
          address: '深圳市深惠公路和布沙公路交汇处',
          metro: '3号线大芬地铁站',
          bus: '大芬地铁站公交站，8路、322路、357路、366路、977路、m172路、m227路、m239路、m358路、m485路、m511路',
          foods: '<p>椰荟四季音乐主题餐厅：龙岗区龙岗大道信和购物广场二楼 </p><p>太阳山艺术中心咖啡馆：龙岗区布吉大芬老围47号</p><p>灌县老妈砂锅串串：龙岗区布沙路217号富文工业园门口</p>',
          specail: '<p>大芬油画街：油画交易集散地</p><p>大芬美术馆：集油画展示、学术交流及休闲、观光为一体</p>',
          content: '大芬村油画产业源起于1989年。如今的大芬村是全国最大的商品油画生产、交易基地，全球重要油画交易集散地，是深圳市最具代表性的文化创意产业园，吸引了大量游客前来游览。'
        },
        {
          logo: require('../../assets/images/B/B3/logo13.png'),
          title: '上围艺术村',
          enTitle: 'Shangwei Art Village',
          address: '深圳市龙华区观湖街道樟坑径社区上围路',
          metro: '',
          bus: '樟坑径公园公交站，m578路',
          foods: '<p>鑫记潮汕砂锅粥美食府：深圳市龙华区宝业路32</p><p>樟坑径农家菜：深圳市龙华区上围路</p><p>佳味美食坊：深圳市龙华区宝业路54-1</p>',
          specail: '<p>上围艺术村的“花海”和古建筑</p><p>上围当代艺术馆</p>',
          content: '上围艺术村是政府搭台、艺术家唱戏的城中村文化治理案例。从一个垃圾如山、隐患重重的破旧老村化身成为一个集艺术化改造、环境治理于一体的生态艺术基地。'
        },
        {
          logo: require('../../assets/images/B/B3/logo14.png'),
          title: '观澜版画原创产业基地',
          enTitle: 'Guanlan Original Industry Base for Printmaking',
          address: '深圳市龙华区裕新路169号',
          metro: '',
          bus: '版画基地公交站，312路、m285路、m288路',
          foods: '<p>版画轩私房菜：深圳市龙华区观澜大水田社区裕新路163号</p><p>港式鱼蛋奶茶：深圳市龙华区兴业路与裕新路交叉口东50米</p><p>鹊尔斯酒窖-台湾料理：深圳市龙华区秋水巷18号</p>',
          specail: '<p>中国版画博物馆</p><p>国际艺术家村</p><p>版画工坊和艺术部落</p>',
          content: '观澜是中国第一代版画家陈烟桥的故乡。观澜版画原创产业基地由一个有300年历史客家古村改造而成，集版画创作、制作、展示、收藏、交流、研究、培训和市场开发为一体，是艺术家静心创作的首选之地。'
        },
        {
          logo: require('../../assets/images/B/B3/logo15.png'),
          title: '鳌湖艺术村',
          enTitle: 'New Who Art Village',
          address: '深圳市龙华区兴业路',
          metro: '',
          bus: '牛湖老村路口公交站，m288路',
          foods: '<p>艺术食堂：深圳市龙华区观澜街道启明街90号</p><p>串门：深圳市龙华区观澜鳌湖艺术村启明街78-1</p><p>启明茶院：深圳市龙华区观澜街道陈烟桥陈列馆对面</p>',
          specail: '<p>鳌湖艺术村客家建筑群</p><p>鳌湖美术馆</p><p>碉楼剧场</p><p>特色商铺</p>',
          content: '鳌湖老村是深圳市龙华区一座有着百年历史的客家村落。2012年，一群艺术家们相继走进这里进行创作，自发形成了鳌湖艺术村。鳌湖美术馆、艺术院落、碉楼剧场、特色商铺包容着艺术家、本地居民、外来者和络绎不绝的游客。'
        }
      ]
    }
  },
  methods: {
    linkBtn () {
      window.open('/static/download/civilization.jpg')
    }
  }
}
</script>

<style lang="less" scoped>
.CityB3 {
  .el-main {
    overflow: initial;
    padding: 0;
  }
  .asaa .asaa-mia .header-title{
    margin: 0.6rem 0;
  }
  .asaa .asaa-mia {
    padding: 20px;
  }
  .top-img {
    position: relative;
  }
  .top-img {
    position: relative;
    background: url('../../assets/images/B/B3/topimg.jpg') no-repeat center;
    height: 7.1666rem;
    .top-img-top {
      position: absolute;
      top: 45%;
      left: 5%;
      color: #FFFFFF;
      text-align: left;
      p{
        font-family: HYQiHeiY1-HEW;
        font-size: 0.6rem;
        font-weight: 600;
      }
      span{
        font-family: HyqiheiY1-DEW;
        font-size: 0.45rem;
        font-weight: 300;
      }
      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: -16px;
        height: 1px;
        width: 40%;
        background: #FFF;
      }
    }
  }
  .introduce{
    margin-top: 0.8rem;
    .txt-box{
      margin-left: 0.6rem;
      text-align: left;
      .txt{
        font-family: HYQiHei-DES;
        margin-top: 0.46rem;
        p{
          text-indent: 2em;
          font-size: 0.25rem;
          color: #585858;
          line-height: 0.444rem;
        }
      }
    }
  }
  .map{
    margin-top: 1rem;
    background: #0166FF;
  }
  .show-box{
    margin-bottom: 1rem;
    .card{
      display: flex;
      flex-wrap: wrap;
      align-items: center;;
      padding: 0.4rem 0;
      margin-top: 0.7rem;
      &:nth-child(2n+1){
        background: #FFF2F1;
      }
      &:nth-child(2n){
        background: #E8F9F9;
      }
      .logo-img{
        padding: 0 0.64rem;
      }
      .content{
        padding: 0.83rem 0;
        text-align: left;
        .top{
          display: flex;
          align-items: center;
          .num{
            width: 0.8rem;
            height: 0.8rem;
            line-height: 0.8rem;
            background: #0066FF;
            border-radius: 50%;
            font-size: 0.6rem;
            font-family: HYQiHeiY1-HEW;
            font-weight: bolder;
            text-align: center;
            color: #fff;
          }
          .title{
            margin-left: 0.2rem;
            .en{
              font-family: "HYQIHEI GZS";
              font-size: 0.25rem;
              font-weight: bold;
              color: #094DB5;
            }
            .zh{
              font-family: "HYQIHEI GZS";
              font-size: 0.37rem;
              color: #0066FF;
              font-weight: bolder;
            }
          }
        }
        .base{
          font-family: HYQiHei-DES;
          margin-top: 0.35rem;
          color: #094DB5;
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
          .txt{
            font-size: 0.25rem;
          }
          .item{
            margin-top: 0.4rem;
            display: flex;
            .txt{
              margin-left: 0.2rem;
            }
          }
          .left{
            padding-right: 0.64rem;
          }
          .right{
            padding-right: 0.64rem;
          }
        }
      }
    }
  }
  .base-download{
    background: #0166FF;
    .download-box{
      display: flex;
      flex-wrap: wrap;
      padding: 0.8rem 0;
      .img-box{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 48%;
          padding: 0 0.2rem;
        }
      }
      .notes-content{
        text-align: left;
        padding-top: 1.2rem;
        padding-left: 1rem;
        .txt{
          color: #fff;
          font-family: HYQiHei-DES;
          font-size: 0.3rem;
          margin-top: 0.2rem;
          margin-left: 0.4rem;
        }
        .button{
          display: inline-block;
          margin-top: 0.8rem;
          margin-left: 0.4rem;
          font-size: 0.25rem;
          padding:0.2rem 1rem;
          background: #FFA200;
          color: #fff;
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }
  }
  @media screen and (max-width: 996px) {
    .top-img{
      height: auto;
    }
    .introduce{
      margin-top: 0;
      .txt-box{
        margin-left: 0;
        margin-top: 0.5rem;
        .txt{
          p{
            font-size: 0.3rem;
          }
        }
      }
    }
    .map{
      margin-top: 0.5rem;
    }
    .show-box{
      margin-bottom: 1rem;
    }
  }
  @media screen and (max-width: 768px) {
    .asaa .asaa-mia{
      padding: 15px;
    }
    .base-download .download-box .notes-content{
      padding-left: 0.4rem;
    }
    .show-box .card{
      .content{
        padding: 0 0.64rem 0.83rem 0.64rem;
        .base{
          .left{
            padding-right: 0;
          }
        }
      }
      .logo-img{
        img{
          width: 70%;
        }
      }
    }
  }
}
</style>
<style lang="stylus" scoped>
  .map-cnts
    width 100%
    .left
      color #fff
      font-size 0.8888rem
      text-align right
      .cnts
        display inline-block
        text-align left
        padding 0 0.1851rem 0 0
        height 100%
        p
          font-family 'HYQiHeiY1-HEW'
          font-weight bold
          &.cnts-m{
            font-size 0.5185rem
            font-family auto
            font-weight 100
          }
    .right
      text-align left
      .cnts
        text-align right
        display inline-block
        padding 0 0 0 0.1851rem
        height 100%
        .dw-btn
          display inline-block
          color #fff
          font-size 0.2962rem
          background #ffa200
          border-radius 0.2rem
          padding 0.2rem 1rem
          margin 0.3rem 0 0.4rem 0
          cursor pointer
          transition all .2s
          &:hover{
            background #ffc000
          }
        p
          color #fff
          font-size 0.6296rem

  @media screen and (max-width: 996px) {
    .map-cnts{
      padding .4rem .2rem
      .left{
        .cnts{
          display flex
          align-items center
          justify-content flex-end
        }
      }
      .right{
        display block
        .cnts{
          display inline-flex
          justify-content center
          align-items center
          .dw-btn{
            margin 0
            font-size 0.4444rem
            text-align center
            padding 0.2rem 1.2rem
          }
        }
      }
    }
  }
</style>
<style lang="less">
.CityB3{
  .el-main {
    overflow: initial;
  }
  .el-popover .el-popover__title {
    height: 20px;
    font-size: 0.3rem;
    text-align: center;
    align-items: center;
    line-height: 34px;
    color: #6a6969;
  }
  .asaa-mia {
    position: relative;
  }
  .asaa .asaa-mia .ec-top-foots {
    position: absolute;
    top: -.78rem;
    left: 20px;
    background: rgba(0, 182, 205,1);
    min-width: 6.5rem;
    height: 0.78rem;
    align-items: center;
  }
  .asaa .asaa-mia .ec-top-foots .el-breadcrumb {
    line-height: 0.78rem;
    padding-left: 10px;
  }
  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__inner a {
    color: #fff;
    font-weight: 400;
  }
  .el-breadcrumb__item .el-breadcrumb__separator {
    color: #fff;
  }
  .el-breadcrumb__inner a:hover {
    color: #fff600;
  }
}
</style>
