<template>
    <div class="CityB3">
        <el-container>
            <el-header style="padding: 0" height="100%">
              <vheadere class="hidden-sm-and-down"></vheadere>
              <ivheaderae class="hidden-md-and-up"></ivheaderae>
                <div class="top-img">
                  <!-- <img class="hidden-sm-and-down" width="100%" src="../../assets/images/B/B3/topimg.jpg" alt=""> -->
                  <img class="hidden-md-and-up" width="100%" src="../../assets/images/B/B3/topimg-m.jpg" style="display: block;" alt="" />
                    <div class="top-img-top">
                      <p>JUST SHENZHEN</p>
                      <span>Culture & Creative Route</span>
                    </div>
                </div>
            </el-header>
            <el-main style="margin-top: 0" class="asaa">
              <div class="w asaa-mia">
                <div class="ec-top-foots hidden-sm-and-down">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item > <router-link to="/JustSZEN">The ADVENTURE</router-link></el-breadcrumb-item>
                    <el-breadcrumb-item>Culture & Creative Route</el-breadcrumb-item>
                  </el-breadcrumb>
                </div>
                <div class="introduce">
                  <el-row type="flex" style="align-items: flex-end;flex-wrap: wrap">
                    <el-col :xs="24" :sm="24" :md="12">
                      <video src="../../assets/images/B/B3/top-video.mp4" poster="../../assets/images/B/B3/video-img.png" controls style="width: 100%;"></video>
                      <!-- <img width="100%" src="../../assets/images/B/B3/video-img.png" alt=""> -->
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12">
                      <div class="txt-box">
                        <img width="54%" src="../../assets/images/B/B3/title.png" alt="">
                        <div class="txt">
                          <p style="text-indent: unset">Since the launch of Just Shenzhen's first citywide Discovery Route in March 2019, we have been trying to dive into a series of specific topics that can further present Shenzhen as a vivid and fascinating city for everyone.And the first of those themes we have chosen to focus on is Shenzhen's culture and arts industry.
                          </p>
                          <p style="text-indent: unset;margin-top: 0.2rem">In fact, besides its technological and economic development, Shenzhen has gradually begun to be known as a "Design City" and be recognized for its booming "Culture and Creative" scene by its residents, overseas compatriots and international friends.
                          </p>
                          <p style="text-indent: unset;margin-top: 0.2rem">The bilingual Discovery Route featuring "Culture and Creativity" is designed to educate the public about the development of Shenzhen's culture and arts industry. Participants following this route can learn more about Shenzhen in a fun and innovative manner. This Discovery Route is designed to enhance a sense of belonging to the city, while increasing attention on Shenzhen's rapidly expanding "Culture and Creativity" landscape.
                          </p>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="map">
                  <img width="100%" src="../../assets/images/B/B3/map.jpg" alt="">
                  <div class="map-cnts">
                    <el-row type="flex">
                      <el-col :xs="24" :sm="24" :md="12" class="left">
                        <div class="cnts">
                          <p class="hidden-sm-and-down">JUST</p>
                          <p class="hidden-sm-and-down">SHENZHEN</p>
                          <p class="cnts-m hidden-md-and-up">Culture & Creative Route</p>
                        </div>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="12" class="right">
                        <div class="cnts">
                          <a href="/static/download/civilization.jpg" class="dw-btn" target="_blank">Download the map</a>
                          <p class="hidden-sm-and-down">Culture & Creative Route</p>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div class="show-box">
                  <div class="card" v-for="(item,index) in cardData" :key="index">
                    <el-col :xs="24" :sm="12" :md="8">
                      <div class="logo-img">
                        <img width="100%" :src="item.logo" alt="">
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="16">
                      <div class="content">
                        <div class="top">
                          <div>
                            <div class="num">{{index+1}}</div>
                          </div>
                          <div class="title">
                            <div class="en">{{item.enTitle}}</div>
                            <div class="zh" v-show="false">{{item.title}}</div>
                          </div>
                        </div>
                        <div class="base">
                          <el-col :xs="24" :sm="24" :md="12">
                            <div class="left">
                              <div class="txt">
                                {{item.content}}
                              </div>
                              <div class="item">
                                <div class="logo">
                                  <img src="../../assets/images/B/B3/address.png" alt="">
                                </div>
                                <div class="txt">
                                  <p>{{item.address}}</p>
                                </div>
                              </div>
                              <div class="item" v-show="item.metro!=''">
                                <div class="logo">
                                  <img src="../../assets/images/B/B3/metro.png" alt="">
                                </div>
                                <div class="txt">
                                  <p>{{item.metro}}</p>
                                </div>
                              </div>
                              <div class="item">
                                <div class="logo">
                                  <img src="../../assets/images/B/B3/bus.png" alt="">
                                </div>
                                <div class="txt">
                                  <p>{{item.bus}}</p>
                                </div>
                              </div>
                            </div>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="12">
                            <div class="right">
                              <div class="item">
                                <div class="logo">
                                  <img src="../../assets/images/B/B3/foods.png" alt="">
                                </div>
                                <div class="txt" v-html="item.foods">
                                </div>
                              </div>
                              <div class="item">
                                <div class="logo">
                                  <img src="../../assets/images/B/B3/special.png" alt="">
                                </div>
                                <div class="txt" v-html="item.specail">
                                </div>
                              </div>
                            </div>
                          </el-col>
                        </div>
                      </div>
                    </el-col>
                  </div>
                </div>
              </div>
              <div class="base-download">
                <div class="w">
                  <div class="download-box">
                    <el-col :xs="24" :sm="24" :md="12">
                      <div class="img-box">
                        <img src="../../assets/images/B/B3/map-download01.jpg" alt="">
                        <img src="../../assets/images/B/B3/map-download02.jpg" alt="">
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12">
                      <div class="notes-content">
                        <img src="../../assets/images/B/B3/title02.png" alt="">
                        <div class="txt">Now,all the above attractions can be found in the "Just Shenzhen — Culture and Creative Route" initiated by CityPlus!
                        </div>
                        <div class="button" @click="linkBtn">
                          Download the map
                        </div>
                      </div>
                    </el-col>
                  </div>
                </div>
              </div>
            </el-main>
            <el-footer>
              <vfootere class="hidden-sm-and-down"></vfootere>
              <ivfootere class="hidden-md-and-up"></ivfootere>
            </el-footer>
        </el-container>
    </div>
</template>
<script>
export default {
  data () {
    return {
      cardData: [
        {
          logo: require('../../assets/images/B/B3/logo01.png'),
          title: '海上世界文化艺术中心',
          enTitle: 'Sea World Culture and Arts Center',
          address: 'No.1187, Wanghai Road, Nanshan District, Shenzhen',
          metro: 'Take Exit A of the Sea World Metro Station,then walk for 5 minutes towards the cruise ship',
          bus: 'Sea World South Bus Station, Routes 22, 79, 80',
          foods: '<p>Raindrops Cafe: 2nd floor, Sea World Coastal Commercial Center, Taizi Road, Shekou</p><p>Japanese Restaurant & Bar "Dragon": No.9 Xinghua Road Road, Nanshan District, Shenzhen</p><p>Deck Restaurant: No. 4, Minghua Cruise Ship 4th floor, Minghua Cruise Ship, Sea World, Taizi Road, Shekou Industrial Zone, Nanshan District, Shenzhen</p>',
          specail: '<p>Statue of Goddess Nüwa: Spirit of Shekou</p><p>Sea World: Shekou landmark</p><p>Coastal Promenade: Sunset sunrise viewing site</p>',
          content: 'Sea World Culture and Arts Center, located in Shekou Sea World - an expatriate hub in Shenzhen, features several top-class exhibition halls for culture and arts and aims to be the next visionary site for the spreading of arts and culture globally.'
        },
        {
          logo: require('../../assets/images/B/B3/logo02.png'),
          title: 'G&G创意社区',
          enTitle: 'G&G Creative Park',
          address: 'G38, No.9 Liyuan Road, Nanshan District, Shenzhen',
          metro: ' Take Line 2 to Shuiwan Metro Station, then walk for 5 minutes to the north',
          bus: 'Nanbo Dasha Bus Station, Route M409',
          foods: '<p> Food Space, G&G Community West Side: No.9 Liyuan Road, Nanshan District, Shenzhen</p><p>B+Cafe: No.1057 Technology Building, Nanhai Avenue, Nanshan District, Shenzhen</p><p>Huayang Restaurant: Ground Floor, Huayang Hotel, 14 Shuiwan Road, Nanshan </p>',
          specail: '<p> G&G Creative Community: Food space and creative cultural specialty shops in container structures </p><p>QC 101 Community: Food space, and music events hosted from time to time</p>',
          content: 'A design experiential and industrial creative center focusing on the exploration of new lifestyles, the G&G Creative Community is a creative destination and content incubator driven by a shared economy. The many new commercial arts initiatives the community has kickstarted include: ing Market, ING Creative Space, ing Street, Crazy Grass Festival, Creative Forum, etc.'
        },
        {
          logo: require('../../assets/images/B/B3/logo03.png'),
          title: '华•美术馆',
          enTitle: 'OCT Art and Design Gallery',
          address: 'No.9009 Shennan Avenue, Nanshan District, Shenzhen',
          metro: 'Take Line 1, then go to Exit C of Huaqiaocheng Metro Station',
          bus: 'He Xiangning Art Museum Bus Station, Routes 21, 26, 32, 101, 113, 204, 222, 223, 234, 338, 369, M123, M132, M183, M398, M433, M487, M488, N4, N6',
          foods: '<p>Huashan Chinese Restaurant: L Floor, InterContinental Shenzhen, 9009 Shennan Avenue</p><p>Hongzhuanfang Village Restaurant: No.102 Sea View Food Street, Overseas Chinese Town, Nanshan District, Shenzhen</p><p>Tingyue (Zhejiang cuisine): No.2 Xinglong Street, Overseas Chinese Town,Nanshan District, Shenzhen</p>',
          specail: '<p> He Xiangning Art Museum: Cultural landmark in the area </p><p>Hua Art Museum: Cultural landmarks in the area</p>',
          content: 'OCT Art and Design Gallery is the country\'s ﬁrst design-themed art museum, with an exterior shaped like a beehive. Along with the He Xiangning Art Museum and the OCT Contemporary Art Terminal, it forms part of the "Art Triangle" in the Overseas Chinese Town. The museum also houses an Art Salon - a comprehensive engagement platform for art lovers.'
        },
        {
          logo: require('../../assets/images/B/B3/logo04.png'),
          title: '何香凝美术馆',
          enTitle: 'Hexiangning Art Museum',
          address: 'No.9013 Shennan Avenue, Nanshan District, Shenzhen',
          metro: 'Take Line 1, then go to Exit C of Huaqiaocheng Metro Station',
          bus: 'He Xiangning Art Museum Bus Station, Routes 21, 26, 32, 101, 113, 204, 222, \t223, 234, 338, 369, M123, M132, M183, M398, M433, M487, M488, N4, N6',
          foods: '<p>Huashan Chinese Restaurant: L Floor, InterContinental Shenzhen, 9009 Shennan Avenue</p><p>Hongzhuanfang Village Restaurant: No.102 Sea View Food Street, Overseas Chinese Town, Nanshan District, Shenzhen</p><p>Tingyue (Zhejiang cuisine): No.2 Xinglong Street, Overseas Chinese Town, Nanshan District, Shenzhen</p>',
          specail: '<p> He Xiangning Art Museum: Cultural landmark in the area </p><p>Hua Art Museum: Cultural landmarks in the area</p>',
          content: ' Located in the Overseas Chinese Town in Shenzhen, the museum first opened its doors on April 18, 1997. With the inscription of its name handwritten by Jiang Zeming, it is the first national art museum named after an individual. A prestigious institution in Chinese art, academia and for society at large, the museum is extending its influence to the global stage.'
        },
        {
          logo: require('../../assets/images/B/B3/logo05.png'),
          title: '万象天地',
          enTitle: 'Mixc World',
          address: 'Intersection of Shennan Avenue and Shahe West Road, Nanshan District, Shenzhen',
          metro: ' Take Metro Line 1, then go to Exit A of Gaoxinyuan Metro Station ',
          bus: 'Dachong (2) Bus Station, Routes 19, 21, 42, 45, 70, 72, 79, 101, 113,204, 223, \t\t234, B683, M109, M388, M398, M448',
          foods: '<p> Chen Pengpeng Duck Rice Shop: B134, Mixc World, Nanshan District, Shenzhen </p><p>Banyan Tree Restaurant: SL186, Mixc World, Nanshan District, Shenzhen </p><p>Xinghuatang Shanxi Cuisine: B134, Mixc World, Nanshan District, Shenzhen</p>',
          specail: '<p>Bubblecoat Elephant: Represented art installation</p><p>SeeSaw Coffee: Popular cafe</p><p>The eslite spectrum: Specialty bookstore + creative merchandise</p><p>Various hipster brand stores</p>',
          content: 'Located in the Nanshan District of Shenzhen, Mixc World integrates cafes, food and \tshopping into one, incorporating innovative, cultural and humanities-related, \tfashion, art, and social elements. Oﬀering an "all-new leisurely urban creative \tspace", the mall has become a popular hit for social media inﬂuencers.'
        },
        {
          logo: require('../../assets/images/B/B3/logo06.png'),
          title: '高北十六创意园',
          enTitle: 'Gaobei 16 Creative Park "Block Sixteen"',
          address: 'No.16 Gaoxin North 6th Road Shenzhen High-Tech Park',
          metro: 'Exit the Shenzhen University Metro Station, walk for 1.5 kilometers to the north \n' +
            'Or reach Exit B of the Gaoxin Park, then walk further for 2 kilometers to the north',
          bus: ' Keyuan Beihuan interchange (2) Bus station,Routes 36, 81, 236, 316, 334, 339, \n' +
            'B607, E9, M109, M243, M299, M429, M474, M539',
          foods: '<p> The park features the "Xiangyu" music hotpot restaurant, the "Ganqishi" Baozi restaurant, "Miss Honey" afternoon tea, "Xishi" Handmade Toast Master, SE Bread & Tea, and Chuen Tsui Sichuan Spicy Skewers. \n' +
            'Address: No.2-3, Xinxi Road, Nanshan District, Shenzhen</p>',
          specail: '<p>Block Sixteen Containers: Ideal place for photography</p><p>Creative Space: creative cultural events hosted from time to time</p>',
          content: 'Block Sixteen is a creative park located at 16 Gaoxin North 6th Road, Shenzhen\n' +
            'High-Tech Park, hence its name. The park explores and creates prototype street zones for lifestyles of the future, with a focus on the cultural, creative and technological industries. Arts and entertainment events such as concerts, forums and book clubs are frequently held in the park.'
        },
        {
          logo: require('../../assets/images/B/B3/logo07.png'),
          title: '华侨城创意文化园',
          enTitle: 'OCT LOFT',
          address: 'No.2 Jinxiu North Street, Nanshan District',
          metro: 'Exit A of the Qiaocheng East Metro Station',
          bus: 'Qiaocheng East Metro Bus Station, Routes\n' +
            ' 21, 26, 32, 79, 338, 369, M123, M132, M398, M487, M488',
          foods: '<p>  Lu Patisserie: 1D, Building 1, Enping Street, OCT-LOFT, Nanshan District, Shenzhen</p><p> My Noodle: F1-105A, OCT-LOFT, Nanshan District, Shenzhen</p><p>Yi Zhou (porridge): Building F3, OCT-LOFT, Nanshan District, Shenzhen</p>',
          specail: '<p>Old Heaven Books: Local cultural space</p><p>B10 Live: Hosts arts events</p><p>T Street: Display shop for cultural and creative productsl</p>',
          content: 'OCT-LOFT takes its name from the reconstruction of factory buildings. Combining creativity, design and art, the park is shaping up to be a landmark structure in Shenzhen and a new open community for cultural industries,  serving as a hub for arts events and Shenzhen\'s hipsters.'
        },
        {
          logo: require('../../assets/images/B/B3/logo08.png'),
          title: '深圳当代艺术与城市规划馆',
          enTitle: 'Shenzhen Museum of Contemporary Art and Urban Planning',
          address: 'No.184 Fuzhong Road, Lianhua Avenue, Futian District, Shenzhen',
          metro: '',
          bus: 'Civic center Bus Station, Routes 38, 60, 107, 236, 398,E28, M123, M262, M390, N9, and Peak-Time Express Route 28',
          foods: '<p>Cloud Sculpture Cafe: Located in the Cloud Sculpture of the Shenzhen Museum of Contemporary Art and Urban Planning </p><p>Grey Wolf Restaurant: Elevator entrance, 5, 1st floor,  Central Book City South Zone, Fuzhong 1st Road, Futian District</p><p>J-House: 1st floor, Central Book City South Zone, 2014 Fuzhong 1st Road, Futian District</p>',
          specail: '<p>Guan Shanyue Art Museum: Cultural landmark in the area</p><p>Shenzen Museum of Contemporary Art and Urban Planning: Cultural landmark in the area</p><p>Shenzhen Concert Hall and Central Book City: Cultural landmark in the area</p><p>Lotus Hill: A desirable location overlooking Futian District, hosting the Glades Music Festival regularly</p><p>Shenzhen Museum: Features the development history of Shenzhen</p>',
          content: 'The Shenzhen Museum of Contemporary Art and Urban Planning was designed\n' +
            '\tBy "deconstructivism pioneer" Coop Himmelb(l)au. As the new landmark for culture and arts in Shenzhen\'s Futian District, the museum functions both as a site of cultural exchange and architectural exhibition under the same surface.'
        },
        {
          logo: require('../../assets/images/B/B3/logo09.png'),
          title: '深业上城',
          enTitle: 'Shum Yip Upperhills',
          address: 'North-west of the intersection of Huanggang Road and Sungang West Road, Futian District Central North, Shenzhen',
          metro: 'Take the Metro to the Lianhuacun Metro Station on Line 3,\n' +
            'exit the Metro station and walk for 1.5 kilometers to the north',
          bus: 'Lianhua Yicun Bus Station, Routes 11, 12, 58, 79, 102, 104, 213\n' +
            '323, 363, 365, 398, M169, M391, M392, M454, M500, M511',
          foods: `<p>Wuu's Hong Kong Cuisine: Shop No. T3083, 3rd floor, Shum Yip Upperhills Town, Futian District</p><p>Jingshengyu Tea Shop: Shop No. T3075, 3rd floor, Shum Yip Upperhills Town, Futian District</p><p>Longxi Hotpot: T3091, Building 9, Shum Yip Upperhills Commercial Complex, Futian District</p>`,
          specail: '<p>LOFT Town: Creative cultural shops such as Benlai bookstore and We Gallery</p><p>CEEC: Display shop for high-tech products</p>',
          content: 'Located in the center of Futian District and surrounded by three major central parks, it is connected to the Lotus Hill and Bijiashan Park by the scenery bridge. Shum Yip Upperhills is a leader in the concept of "high-end quality living", focusing on creative design, product innovation and quality concepts. The LOFT town on the third floor of Shum Yip Upperhills is a favorite for social media influencers.'
        },
        {
          logo: require('../../assets/images/B/B3/logo11.png'),
          title: '关山月美术馆',
          enTitle: 'Guan Shanyue Art Museum',
          address: 'No.6026 Hongli Road, Futian District, Shenzhen',
          metro: 'Take the Metro to the Shaonian Gong Station, go to the F2 exit, and walk for 370 meters to the east',
          bus: 'Guan Shanyue Art Museum (1) Bus Station, Routes 34, 322, 398, M105, M173,M183, M262, M459, M488, M511, Peak-Time Route 123, and Peak-Time Express Route 18Guan Shanyue Art Museum (2) Bus Station, Routes 10, 14, 64, 108, 111, 215, M555 and Main\n' +
            'Station Express Route M555',
          foods: '<p>Tianzi Vegetarian Restaurant: 1st floor of Guan Shanyue Art Museum, Futian District</p><p>Guanshe Visual Art Restaurant: 2nd floor of Guan Shanyue Art Museum, Futian District</p><p>J-House: 1st floor, Central Book City South Zone, 2014 Fuzhong 1st Road, Futian District</p>',
          specail: '<p>Guan Shanyue Art Museum: Cultural landmark in the area</p><p>Shenzen Museum of Contemporary Art and Urban Planning: Cultural landmark in the area </p><p>Shenzhen Concert Hall and Central Book City: Cultural landmark in the area</p><p>Lotus Hill: A desirable location overlooking Futian District, hosting the Glades Music Festival regularly</p><p>Shenzhen Museum: Features the development history of Shenzhen</p>',
          content: 'Guan Shanyue Art Museum is a national public welfare cultural organization named after the famous Chinese painter Guan Shanyue. As an important platform for international artistic dialogue and exchange, the museum has hosted close to 100 international art events and exhibitions.'
        },
        {
          logo: require('../../assets/images/B/B3/logo11.png'),
          title: '深圳大剧院',
          enTitle: 'Shenzhen Grand Theater',
          address: 'No.5018 Shennan East Road Luohu District, Shenzhen',
          metro: ' Line 1, Da Juyuan Metro Station',
          bus: ' Da Juyuan Bus Station, Routes 7, 23, 62, 63, 377, M152, M360',
          foods: '<p>Yuanshao Jiuchang (Japanese charcoal roast): F1, Kingkey Banner Center, KK Mall, No.5016 Shennan East Road, Luohu District</p><p>Xi Mian Tang: F1, Kingkey Banner Center, KK Mall, No.5016  Shennan East Road, Luohu District</p><p>Fishing Boat No. 79 Seafood-Themed Restaurant: No.1002-2 Hongling Middle Road, Luohu District, Shenzhen</p>',
          specail: '<p> Grand Theater: Cultural landmark in the area</p><p>KK100: Second tallest building in Shenzhen</p><p>KK Mall: For leisure, entertainment and shopping</p>',
          content: 'Built in 1984, the Shenzhen Grand Theater was the ﬁrst construction in China that introduced a stage design shaped after the character "pin" and was built using advanced mechanical equipment from abroad. It has since become the progressive symbol for modern theater architecture in China, marking the beginning of the "Era of the Grand Theater".'
        },
        {
          logo: require('../../assets/images/B/B3/logo12.png'),
          title: '大芬油画村',
          enTitle: 'Dafen Oil Painting Village',
          address: 'Dafen Oil Painting Village is located at the intersection of Shenhui Highway and Busha Highway, Shenzhen',
          metro: ' Line 3, Dafen Metro Station',
          bus: 'Dafen Metro Bus Station, Routes 8, 322, 357, 366, 977, M172, M227, M239, M358, M485, M511',
          foods: '<p>Yehui Four Seasons Music Restaurant: 2rd floor, Xinhe Shopping Plaza, Longgang Avenue, Longgang District</p><p>Sunrise Art Center Cafe: 47, Laowei, Dafen,  Buji, Longgang District</p><p>Guan County Laoma Claypot and Skewers: Entrance of Fuwen Industrial Park, 217 Busha Road, Longgang District</p>',
          specail: '<p>Dafen Oil Painting Street: Oil painting distribution center</p><p>Dafen Art Museum: Integrates oil painting exhibitions, academic exchanges, and leisure and tourism</p>',
          content: 'The oil painting industry of Dafen Village traces its roots back to 1989. Today\'s Dafen Village is the biggest production and trading base for commercial oil painting in the country, and an important oil painting distribution center in the world. It is a creative cultural industry area that is most representative of Shenzhen, and attracts a large number of visitors.'
        },
        {
          logo: require('../../assets/images/B/B3/logo13.png'),
          title: '上围艺术村',
          enTitle: 'Shangwei Art Village',
          address: 'Shangwei Road, Zhangkengjing Community Guanhu Street, Longhua District, Shenzhen',
          metro: '',
          bus: ' Zhangkengjing Park Bus Station, Route M578',
          foods: '<p> Xin Ji Chaoshan Claypot Porridge: No.32 Baoye Road,Longhua District, Shenzhen</p><p>Zhangkengjing Farmhouse Cooking: Shangwei Road, Longhua District, Shenzhen</p><p>Jiawei Food Court: No.54-1 Baoye Road, Longhua District, Shenzhen</p>',
          specail: '<p>The "Flower Sea" and ancient buildings in Shangwei Art Village</p><p>Shangwei Museum of Contemporary Art</p>',
          content: 'Shangwei Art Village is an urban village cultural project with a government-built platform serving as a performance space for traditional opera artists. It was transformed from an old dilapidated and rubbish-filled village that was unsuitable for living into an ecological base for the arts that blends artistic reinvention with environmental management.'
        },
        {
          logo: require('../../assets/images/B/B3/logo14.png'),
          title: '观澜版画原创产业基地',
          enTitle: 'Guanlan Original Industry Base for Printmaking',
          address: 'No.169 Yuxin Road, Longhua District, Shenzhen',
          metro: '',
          bus: 'Banhua Jidi Bus Station, Routes 312, M285, M288',
          foods: '<p>Home Cuisine at Printmaking Workshop: 163 Yuxin Road, Dashuitian Community, Guanlan, Longhua District, Shenzhen</p><p>Hong Kong Fish Balls and Milk Tea: 50 meters east of the intersection of Xingye Road and Yuxin Road, Longhua District,Shenzhen</p><p>Cheers Wine Cellars - Taiwanese Cuisine: 18 Qiushui Alley, Longhua District, Shenzhen</p>',
          specail: '<p>China Print Art Museum</p><p>International Artist Village</p><p>Printmaking Workshop and Art Tribes</p>',
          content: 'Guanlan is the hometown of China\'s first-generation printmaking artist Chen \tYanqiao. Converted from a 300-year-old Hakka village, the Guanlan Original \tIndustry Base for Printmaking is an ideal and peaceful place much-loved by artists, \twhich combines print art creation, printmaking, and print art exhibition, collection, \texchange, research, training and market development into one.'
        },
        {
          logo: require('../../assets/images/B/B3/logo15.png'),
          title: '鳌湖艺术村',
          enTitle: 'New Who Art Village',
          address: 'Xingye Road, Longhua District, Shenzhen',
          metro: '',
          bus: 'Niuhu Laocun Lukou Bus Station, Route M288',
          foods: '<p>Arts Canteen: No.90 Qiming Street, Guanlan Avenue,Longhua District, Shenzhen</p><p>Chuanmen: No.78-1 Qiming Street, Aohu Art Village Guanlan, Longhua District, Shenzhen</p><p>Qiming Tea Yard: Opposite Chenyanqiao Exhibition Hall,Guanlan Avenue, Longhua District, Shenzhen</p>',
          specail: '<p>Hakka Building Clusterin New Who Art Village</p><p>Aohu Art Museum</p><p>Diaolou Theater</p><p>Specialty shops</p>',
          content: 'New Who Art Village is a century-old Hakka village in the Longhua District of Shenzhen. In 2012, a group of artists came and started exploring the village as an artistic base for creative work, which evolved into Aohu Art Village today. Featuring the Aohu Art Museum, Courtyard of the Arts, Diaolou Theater, and specialty shops, the village embraces artists, local residents, visitors and large numbers of tourists.'
        }
      ]
    }
  },
  methods: {
    linkBtn () {
      window.open('/static/download/civilization.jpg')
    }
  }
}
</script>

<style lang="less" scoped>
.CityB3 {
  .el-main {
    overflow: initial;
    padding: 0;
  }
  .asaa .asaa-mia .header-title{
    margin: 0.6rem 0;
  }
  .asaa .asaa-mia {
    padding: 20px;
  }
  .top-img {
    position: relative;
  }
  .top-img {
    position: relative;
    background: url('../../assets/images/B/B3/topimg.jpg') no-repeat center;
    height: 7.1666rem;
    .top-img-top {
      position: absolute;
      top: 45%;
      left: 5%;
      color: #FFFFFF;
      text-align: left;
      p{
        font-family: HYQiHeiY1-HEW;
        font-size: 0.6rem;
        font-weight: 600;
      }
      span{
        font-family: HyqiheiY1-DEW;
        font-size: 0.45rem;
        font-weight: 300;
      }
      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: -16px;
        height: 1px;
        width: 40%;
        background: #FFF;
      }
    }
  }
  .introduce{
    margin-top: 0.8rem;
    .txt-box{
      margin-left: 0.6rem;
      text-align: left;
      .txt{
        font-family: HYQiHei-DES;
        margin-top: 0.46rem;
        p{
          text-indent: 2em;
          font-size: 0.25rem;
          color: #585858;
          line-height: 0.444rem;
          text-align: justify;
        }
      }
    }
  }
  .map{
    margin-top: 1rem;
    background: #0166FF;
  }
  .show-box{
    margin-bottom: 1rem;
    .card{
      display: flex;
      flex-wrap: wrap;
      align-items: center;;
      padding-top: 0.4rem;
      margin-top: 0.7rem;
      &:nth-child(2n+1){
        background: #FFF2F1;
      }
      &:nth-child(2n){
        background: #E8F9F9;
      }
      .logo-img{
        padding: 0 0.64rem;
      }
      .content{
        padding: 0.83rem 0;
        text-align: left;
        .top{
          display: flex;
          align-items: center;
          .num{
            width: 0.8rem;
            height: 0.8rem;
            line-height: 0.8rem;
            background: #0066FF;
            border-radius: 50%;
            font-size: 0.6rem;
            font-family: HYQiHeiY1-HEW;
            font-weight: bolder;
            text-align: center;
            color: #fff;
          }
          .title{
            margin-left: 0.2rem;
            .en{
              font-family: "HYQIHEI GZS";
              font-size: 0.37rem;
              font-weight: bold;
              color: #094DB5;
            }
            .zh{
              font-family: "HYQIHEI GZS";
              font-size: 0.37rem;
              color: #0066FF;
              font-weight: bolder;
            }
          }
        }
        .base{
          font-family: HYQiHei-DES;
          margin-top: 0.35rem;
          color: #094DB5;
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
          .txt{
            font-size: 0.25rem;
            text-align: left;
          }
          .item{
            margin-top: 0.4rem;
            display: flex;
            .txt{
              margin-left: 0.2rem;
              p{
                word-break: break-word;
              }
            }
          }
          .left{
            padding-right: 0.64rem;
          }
          .right{
            padding-right: 0.64rem;
          }
        }
      }
    }
  }
  .base-download{
    background: #0166FF;
    .download-box{
      display: flex;
      flex-wrap: wrap;
      padding: 0.8rem 0;
      .img-box{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 48%;
          padding: 0 0.2rem;
        }
      }
      .notes-content{
        text-align: left;
        padding-top: 1.2rem;
        padding-left: 1rem;
        .txt{
          color: #fff;
          font-family: HYQiHei-DES;
          font-size: 0.3rem;
          margin-top: 0.2rem;
          margin-left: 0.4rem;
        }
        .button{
          display: inline-block;
          margin-top: 0.8rem;
          margin-left: 0.4rem;
          font-size: 0.25rem;
          padding:0.2rem 1rem;
          background: #FFA200;
          color: #fff;
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }
  }
  @media screen and (max-width: 996px) {
    .top-img{
      height: auto;
    }
    .introduce{
      margin-top: 0;
      .txt-box{
        margin-left: 0;
        margin-top: 0.5rem;
        .txt{
          p{
            font-size: 0.3rem;
          }
        }
      }
    }
    .map{
      margin-top: 0.5rem;
    }
    .show-box{
      margin-bottom: 1rem;
    }
  }
  @media screen and (max-width: 768px) {
    .asaa .asaa-mia{
      padding: 15px;
    }
    .base-download .download-box .notes-content{
      padding-left: 0.4rem;
    }
    .show-box .card{
      .content{
        padding: 0 0.64rem 0.83rem 0.64rem;
        .base{
          .left{
            padding-right: 0;
          }
        }
      }
      .logo-img{
        img{
          width: 70%;
        }
      }
    }
  }
}
</style>
<style lang="stylus" scoped>
  .map-cnts
    width 100%
    .left
      color #fff
      font-size 0.8888rem
      text-align right
      .cnts
        display inline-block
        text-align left
        padding 0 0.1851rem 0 0
        height 100%
        p
          font-family 'HYQiHeiY1-HEW'
          font-weight bold
          &.cnts-m{
            font-size 0.5185rem
            font-family auto
            font-weight 100
            text-align center
          }
    .right
      text-align left
      .cnts
        text-align right
        display inline-block
        /*padding 0 0 0 0.1851rem*/
        height 100%
        .dw-btn
          display inline-block
          color #fff
          font-size 0.2962rem
          background #ffa200
          border-radius 0.2rem
          padding 0.2rem 1rem
          margin 0.3rem 0 0.4rem 0
          cursor pointer
          transition all .2s
          &:hover{
            background #ffc000
          }
        p
          color #fff
          font-size 0.6296rem

  @media screen and (max-width: 996px) {
    .map-cnts{
      padding .4rem .2rem
      .left{
        .cnts{
          display flex
          align-items center
          justify-content flex-end
        }
      }
      .right{
        display block
        .cnts{
          display inline-flex
          justify-content center
          align-items center
          .dw-btn{
            margin 0
            font-size 0.4444rem
            text-align center
            padding 0.2rem .2rem
          }
        }
      }
    }
  }
</style>
<style lang="less">
.CityB3{
  .el-main {
    overflow: initial;
  }
  .el-popover .el-popover__title {
    height: 20px;
    font-size: 0.3rem;
    text-align: center;
    align-items: center;
    line-height: 34px;
    color: #6a6969;
  }
  .asaa-mia {
    position: relative;
  }
  .asaa .asaa-mia .ec-top-foots {
    position: absolute;
    top: -.78rem;
    left: 20px;
    background: rgba(0, 182, 205,1);
    min-width: 6.5rem;
    height: 0.78rem;
    align-items: center;
  }
  .asaa .asaa-mia .ec-top-foots .el-breadcrumb {
    line-height: 0.78rem;
    padding-left: 10px;
  }
  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__inner a {
    color: #fff;
    font-weight: 400;
  }
  .el-breadcrumb__item .el-breadcrumb__separator {
    color: #fff;
  }
  .el-breadcrumb__inner a:hover {
    color: #fff600;
  }
}
</style>
