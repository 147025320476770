<template>
  <div class="CityB2">
    <el-container>
      <el-header style="padding: 0" height="100%">
        <vheadere class="hidden-sm-and-down"></vheadere>
        <ivheaderae class="hidden-md-and-up"></ivheaderae>
        <div class="top-img">
          <!-- <img class="hidden-sm-and-down" width="100%" src="../../assets/images/B/B2/topimg.jpg" alt="" /> -->
          <img class="hidden-md-and-up" width="100%" src="../../assets/images/B/B2/topimg-m.jpg" style="display: block;" alt="" />
          <div class="top-img-top">
            <p>JUST SHENZHEN</p>
            <span>Eco-Discovery Route</span>
          </div>
        </div>
      </el-header>
      <el-main style="margin-top: 0" class="asaa">
        <div class="w asaa-mia">
          <div class="ec-top-foots hidden-sm-and-down">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item> <router-link to="/JustSZ">The ADVENTURE</router-link></el-breadcrumb-item>
              <el-breadcrumb-item>Eco-Discovery Route</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="introduce">
            <el-row type="flex" style="flex-wrap: wrap;align-items: flex-end;">
              <el-col :xs="24" :sm="24" :md="12">
                <div class="topcover"><img src="../../assets/images/B/B2/topcover.jpg" alt="" /></div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12">
                <div class="top-right">
                  <img src="../../assets/images/B/B2/topcovertit.png" />
                  <div class="cnts">
                    <p style="text-indent: unset;">
                      When talking about Shenzhen, you might think of keywords such as economic development, finance, high-tech, entrepreneurship, modernization, vibrant, etc. This city with residents whose average age is 32 has become the dreamland of many entrepreneurs and the young generation. Commuting daily in this bustling city, you may have got used to the fast pace. However, have you ever spent time making a retreat to explore the ecological scenic places of the city?
                    </p>
                    <p style="text-indent: unset;margin-top: 0.2rem">
                      As a "green city" with green space coverage rate of 45.1% in the built-up area, Shenzhen has more than 950 public gardens. The total length of urban greenways reaches 2,400 km. The total area of sustainable architecture in the city is over 60 million square meters. Strolling at park, therefore, is a common leisure for the local residents.
                    </p>
                    <p style="text-indent: unset;margin-top: 0.2rem">
                      "Just Shenzhen" Eco-Discovery Route consists of 12 representative sites, each specially chosen by the initiators to help you explore five main eco-systems of Shenzhen: Coastal Wetlands, Public Parks, Mountains & Forests, Sustainable Architecture and Coastlines.
                    </p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          </div>
          <div class="w">
          <!-- <div class="activelist">
            <div class="tit1 hidden-sm-and-down"><img src="../../assets/images/B/B2/recommendEN.png" /></div>
            <div class="funActive-card">
              <el-row>
                <h3 class="small" ref="funCur">
                  <el-col :xs="24" :sm="24" :md="8" v-for="(item, index1) in 3" :key="index1">
                    <div class="fcard-item">
                      <el-col :xs="12" :sm="24" :md="24">
                        <div class="over-hidden"><img class="item-img" width="100%" src="../../assets/images/B/B2/funcover.jpg" alt="" /></div>
                      </el-col>
                      <el-col :xs="12" :sm="24" :md="24">
                        <div class="fcard-content">
                          <div class="fcard-txt"><p>深圳生态地图体验官招募</p></div>
                          <div class="fcard-icon">
                            <p>
                              <i class="el-icon-time"></i>
                              <span>9-24 00:00 ~ 9-30 00:00</span>
                            </p>
                            <p>
                              <i class="el-icon-location"></i>
                              广东省深圳市-南山区华侨城湿地公园
                            </p>
                          </div>
                          <div class="fcard-foot clearfloat">
                            <img src="../../assets/images/B/B2/funlogo.jpg" alt="" />
                            <span class="display-none">cityplus</span>
                          </div>
                        </div>
                      </el-col>
                    </div>
                  </el-col>
                </h3>
              </el-row>
            </div>
          </div> -->
          </div>
          <div class="w">
          <div class="map">
            <img src="../../assets/images/B/B2/map.jpg" alt="" />
            <div class="map-cnts">
              <el-row type="flex">
                <el-col :xs="24" :sm="24" :md="12" class="left">
                  <div class="cnts">
                    <p class="hidden-sm-and-down">JUST</p>
                    <p class="hidden-sm-and-down">SHENZHEN</p>
                    <p class="cnts-m hidden-md-and-up">Eco-Discovery Route</p>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" class="right">
                  <div class="cnts">
                    <a href="/static/download/ecology.jpg" class="dw-btn" target="_blank">Download the map</a>
                    <p class="hidden-sm-and-down">Eco-Discovery Route</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="scenicspots">
            <el-row type="flex" class="fras">
              <el-col :xs="24" :sm="24" :md="8" class="spotcnts" v-for="(item, index) in datas" :key="index">
                <div class="cnt-fra">
                  <div class="imgfra">
                    <img :src="require(`../../assets/images/B/B2/mapimg${index}.png`)" />
                  </div>
                  <div class="cnts">
                    <p class="tit-zh">
                      <span>{{ index + 1 > 9 ? index + 1 : `0${index + 1}` }}</span>
                      {{ item.enTit }}
                    </p>
                    <!-- <p class="tit-en">{{ item.enTit }}</p> -->
                    <p class="cnt1">
                      <img src="../../assets/images/B/B2/icon1.png" />
                      {{ item.cnt1 }}
                    </p>
                    <p class="cnt2">{{ item.cnt2 }}</p>
                    <p class="cnt3">
                      <img src="../../assets/images/B/B2/icon2.png" />
                      {{ item.cnt3 }}
                    </p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          </div>
        <div class="line"><img src="../../assets/images/B/B2/line.png" alt="" /></div>
        <div class="w">
          <div class="calendar">
            <img src="../../assets/images/B/B2/tit.png" alt="" class="tit hidden-sm-and-down" />
            <img src="../../assets/images/B/B2/tit-m.png" alt="" class="tit hidden-md-and-up" />
            <p class="tit-m hidden-md-and-up">Slide to view more</p>
            <img src="../../assets/images/B/B2/cnt1.png" alt="" class="cnt hidden-sm-and-down" />
            <div class="img-m hidden-md-and-up">
              <img src="../../assets/images/B/B2/cnt1-m.jpg" alt="">
            </div>
            <img src="../../assets/images/B/B2/tit2.png" alt="" class="tit hidden-sm-and-down" />
            <img src="../../assets/images/B/B2/tit2-m.png" alt="" class="tit hidden-md-and-up" />
            <p class="tit-m hidden-md-and-up">Slide to view more</p>
            <img src="../../assets/images/B/B2/cnt2.png" alt="" class="cnt hidden-sm-and-down" />
            <img src="../../assets/images/B/B2/cnt3.png" alt="" class="cnt hidden-sm-and-down" />
            <div class="img-m m2 hidden-md-and-up">
              <img src="../../assets/images/B/B2/cnt2-m.jpg" alt="">
            </div>
            <img src="../../assets/images/B/B2/cnt3-m.png" alt="" class="cnt hidden-md-and-up" />
          </div>
        </div>
        <div class="bom">
          <div class="w asaa-mia">
            <el-row type="flex" style="flex-wrap: wrap;">
              <el-col :xs="24" :sm="24" :md="12" class="bom-cnts">
                <el-col :xs="12" :sm="12" :md="12" class="fra hidden-sm-and-down"><img src="../../assets/images/B/B2/bommap1.png" alt="" /></el-col>
                <el-col :xs="12" :sm="12" :md="12" class="fra hidden-sm-and-down"><img src="../../assets/images/B/B2/bommap2.png" alt="" /></el-col>
                <el-col :xs="24" :sm="24" :md="24" class="fra hidden-sm-and-down" style="margin-top: 0.3333rem;">
                  <div class="bom-icon"><img src="../../assets/images/B/B2/bom-icon1.png" alt="" /></div>
                  <div class="bom-icon"><img src="../../assets/images/B/B2/bom-icon2.png" alt="" /></div>
                  <div class="bom-icon"><img src="../../assets/images/B/B2/bom-icon3.png" alt="" /></div>
                  <div class="bom-icon"><img src="../../assets/images/B/B2/bom-icon4.png" alt="" /></div>
                  <div class="bom-icon"><img src="../../assets/images/B/B2/bom-icon5.png" alt="" /></div>
                </el-col>
              </el-col>
              <el-col :xs="24" :sm="24" :md="8" class="fra hidden-sm-and-down">
                <div class="cnts">
                  <p class="cnt1">JUST</p>
                  <p class="cnt1">SHENZHEN</p>
                  <p class="cnt2">Eco-Discovery Route</p>
                  <a href="/static/download/ecology.jpg" class="dw-btn" target="_blank">Download the map</a>
                </div>
              </el-col>
              <el-col :xs="14" :sm="14" :md="14" class="fra hidden-md-and-up">
                <div class="cnts">
                  <p class="cnt1">JUST</p>
                  <p class="cnt1">SHENZHEN</p>
                  <p class="cnt2">Eco-Discovery Route</p>
                </div>
              </el-col>
              <el-col :xs="10" :sm="10" :md="10" class="fra hidden-md-and-up">
                <div class="cnts">
                  <a href="/static/download/ecology.jpg" class="dw-btn" target="_blank">Download the map</a>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-main>
      <gotop></gotop>
      <el-footer>
        <vfootere class="hidden-sm-and-down"></vfootere>
        <ivfootere class="hidden-md-and-up"></ivfootere>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import { datasen } from '../../assets/js/datas.js'
export default {
  data () {
    return {
      datas: datasen
    }
  },
  mounted () {
  }
}
</script>

<style lang="stylus" scoped>
.CityB2
  .el-main
    overflow initial
    padding 0
  .asaa .asaa-mia .header-title
    margin 0.6rem 0
  .w
    padding 0 0.3703rem
  .asaa .asaa-mia
    padding 1.5555rem 0.3703rem
  .asaa .asaa-mia,.w
    .introduce
      .topcover
        margin-right 5%
        img
          width 100%
      .top-right
        margin-left 5%
        text-align left
        >img
          margin-bottom 0.6481rem
        .cnts
          p
            color #595959
            font-size 0.2592rem
            text-indent 2em
            text-align justify
    .tit1
      text-align left
    .funActive-card
      text-align left
      margin-top 0.5rem
      margin-bottom 0.5rem
      margin-right -0.35rem
      .small
        display inline-block
        width 100%
      .fcard-item
        position relative
        display inline-block
        background #fff
        margin-right 0.3rem
        margin-bottom 0.3rem
        border 1px solid #D5D5D5
        // width 95%
        // border-radius: 5px;
        .over-hidden
          overflow hidden
          .item-img
            transition all 0.3s ease-out 0s
            cursor pointer
            display block
            height 3.7rem
            &:hover
              transform scale(1.1)
        .fcard-content
          padding 0 0.4rem
          .fcard-txt
            position relative
            color #474747
            font-size 0.3rem
            margin-left 0.15rem
            height 1rem
            display flex
            align-items center
            p
              padding-left 0.4rem
              position relative
              &:before
                content ' '
                position absolute
                top 10%
                left 0
                height 80%
                border-right 3px solid #A6CE36
          .fcard-icon
            color #d3d3d3
            font-size 0.25rem
            margin-top 0.2rem
            margin-bottom 0.1rem
            p
              overflow hidden
              text-overflow ellipsis
              white-space nowrap
              i
                color #BFDA5D
                font-size 0.3rem
                margin-right 0.3rem
          .fcard-foot
            display flex
            align-items center
            padding 0.14rem 0
            border-top 1px solid #d3d3d3
            img, span
              float left
            img
              width 0.75rem
            span
              margin-left 0.3rem
  .map
    background #FFF6BE
    >img
      width 100%
      display block
    .map-cnts
      width 100%
      .left
        color #c8a063
        font-size 0.8888rem
        text-align right
        .cnts
          display inline-block
          text-align left
          padding 0 0.1851rem 0 0
          height 100%
          p
            font-family 'HYQiHeiY1-HEW'
            font-weight bold
            &.cnts-m{
              font-size 0.5185rem
              font-family auto
              font-weight 100
              text-align center
            }
      .right
        text-align left
        .cnts
          text-align right
          display inline-block
          padding 0 0 0 0.1851rem
          height 100%
          .dw-btn
            display inline-block
            color #fff
            font-size 0.2962rem
            background #ffa200
            border-radius 0.2rem
            padding 0.2rem 1rem
            margin 0.3rem 0 0.4rem 0
            cursor pointer
            transition all .2s
            &:hover{
              background #ffc000
            }
          p
            color #c8a063
            font-size 0.6296rem
  .scenicspots
    .fras
      flex-wrap wrap
      .spotcnts
        border-top 1px dashed #917035
        padding 2% 0
        &:nth-child(1){
          border-top none
        }
        &:nth-child(2){
          border-top none
        }
        &:nth-child(3){
          border-top none
        }
        &:nth-child(3n+3)
          .cnt-fra
            border none
        &:nth-last-child(1){
          .cnt-fra{
            .cnts{
              .cnt2{
                min-height 2.8rem
              }
            }
          }
        }
        &:nth-last-child(2){
          .cnt-fra{
            .cnts{
              .cnt2{
                min-height 2.8rem
              }
            }
          }
        }
        &:nth-last-child(3){
          .cnt-fra{
            .cnts{
              .cnt2{
                min-height 2.8rem
              }
            }
          }
        }
        &:nth-last-child(4){
          .cnt-fra{
            .cnts{
              .cnt2{
                min-height 3.6rem
              }
            }
          }
        }
        &:nth-last-child(5){
          .cnt-fra{
            .cnts{
              .cnt2{
                min-height 3.6rem
              }
            }
          }
        }
        &:nth-last-child(6){
          .cnt-fra{
            .cnts{
              .cnt2{
                min-height 3.6rem
              }
            }
          }
        }
        .cnt-fra
          padding 0 4%
          border-right 1px dashed #917035
          display flex
          flex-wrap wrap
          height 100%
          flex-direction column
          .imgfra
            height 8rem
            display flex
            align-items center
            justify-content center
            >img
              display block
              margin 0 auto
              width 90%
          .cnts
            text-align left
            align-self flex-end
            img
              margin 0.05rem 0.1rem 0 0
            .tit-zh
              color #a47841
              font-size 0.38rem
              font-weight bold
              padding 0.4rem 0 0 0
              display flex
              span
                margin 0 0.18rem 0 0
            .tit-en
              color #a47841
              font-size 0.2592rem
              padding-left 0.74rem
              font-weight bold
            .cnt1
              color #44ab6f
              font-size 0.2592rem
              padding 0.1rem 0 0.1rem 0.74rem
              display flex
              align-items flex-start
              min-height: 1rem;
            .cnt2
              color #a47841
              font-size 0.2592rem
              padding 0.1rem 0 0.1rem 0.74rem
              display flex
              min-height 4rem
            .cnt3
              color #a47841
              font-size 0.2592rem
              padding 0.1rem 0 0.1rem 0.74rem
              display flex
              align-items flex-start
  .line
    text-align right
    img
      width 70%
  .calendar
    text-align left
    padding-bottom .5rem
    .tit
      padding 1.1666rem 0 0.5rem 0
    .cnt
      width 100%
  .bom
    width 100%
    background #c8a063
    color #fff
    .bom-cnts{
      .bom-icon{
        width 20%
        margin-right .1rem
        display inline-block
        height 4rem
        &:last-child{
          margin-right 0
        }
        img{
          width 100%
          height 100%
          object-fit cover
          border 2px solid #fff
        }
      }
    }
    .fra
      text-align left
      padding 0 0.3703rem
      display flex
      align-items center
      img
        width 100%
      .cnts
        .cnt1
          font-size 0.8888rem
          font-weight bold
          line-height 1.3
          font-family 'HYQiHeiY1-HEW'
          font-weight bold
        .cnt2
          font-size 0.6296rem
          line-height 1.3
        .dw-btn
          display inline-block
          color #fff
          font-size 0.2962rem
          background #ffa200
          border-radius 0.2rem
          padding 0.2rem 1rem
          margin 0.3rem 0 0 0
          cursor pointer
          transition all .2s
          &:hover{
            background #ffc000
          }
  .top-img
    position relative
    background url('../../assets/images/B/B2/topimg.jpg') no-repeat center
    height 7.1666rem
    img
      display block
    .top-img-top
      position absolute
      top 45%
      left 5%
      color #FFFFFF
      text-align left
      p
        font-size 0.6rem
        font-weight 600
        font-family 'HYQiHeiY1-HEW'
      span
        font-size 0.45rem
        font-weight 300
      &:after
        content ''
        position absolute
        left 0
        top -16px
        height 1px
        width 40%
        background #FFF
@media screen and (max-width: 990px){
  .CityB2{
    .top-img{
      height auto
    }
    .w{
      .funActive-card{
        .fcard-item{
          display flex
          justify-content center
          align-items center
          margin-top 0.3rem
          .over-hidden{
            .item-img{
              height 2.5rem
            }
          }
          .fcard-content{
            position relative
            .fcard-icon{
              p{
                overflow hidden
                text-overflow ellipsis
                white-space nowrap
                max-width 3rem
              }
            }
            .fcard-foot{
              padding 0
              border none
              img{
                position absolute
                right 6%
                bottom 5%
              }
              span{
                display none
              }
            }
          }
        }
      }
    }
    .asaa{
      .asaa-mia{
        padding 0.54rem 0.3703rem
        .introduce{
          .topcover{
            margin 0
          }
          .top-right{
            margin 0
            >img{
              width 50%
              margin-bottom 0.4rem
              margin-top 0.4rem
            }
            .cnts{
              p{
                font-size 0.3703rem
              }
            }
          }
        }
        .tit1{
          img{
            width 50%
          }
        }
      }
    }
    .map{
      .map-cnts{
        padding .4rem 0
        .left{
          .cnts{
            display flex
            align-items center
            justify-content flex-end
          }
        }
        .right{
          display block
          .cnts{
            display inline-flex
            justify-content center
            align-items center
            .dw-btn{
              margin 0
              font-size 0.4444rem
              text-align center
              padding 0.2rem .2rem
            }
          }
        }
      }
    }
    .scenicspots{
      .fras{
        .spotcnts{
          padding 4% 0
          &:nth-child(2){
            border-top 1px dashed #917035
          }
          &:nth-child(3){
            border-top 1px dashed #917035
          }
          .cnt-fra{
            border none
            .imgfra{
              height 14rem
              >img{
              }
            }
            .cnts{
              .cnt1{
                font-size 0.3333rem
              }
              .cnt2{
                font-size 0.3703rem
              }
              .cnt3{
                font-size 0.3333rem
              }
              img{
                width 4%
                margin-top .1rem
              }
            }
          }
        }
      }
    }
    .line{
      img{
        width 100%
        padding .4rem 0
      }
    }
    .calendar{
      .tit{
        width 45%
        vertical-align middle
      }
      .tit-m{
        width 55%
        display inline-block
        padding 1.1666rem 0 0.5rem 0
        color #a47841
        font-size 0.4444rem
        vertical-align middle
        text-align right
        font-weight bold
      }
      .img-m{
        width 100%
        height 10.4rem
        overflow-x auto
        -webkit-overflow-scrolling touch
        padding-bottom .4rem
        &::-webkit-scrollbar{
          height .2rem
          border-radius .2rem
          width 100%
        }
        &::-webkit-scrollbar-track-piece{
          background #dcdcdc
          border-radius .2rem
        }
        &::-webkit-scrollbar-thumb{
          background #a47741
          border-radius .2rem
        }
        img{
          height 100%
          display block
        }
        &.m2{
          height 16.4rem
        }
      }
    }
    .bom{
      .fra{
        &:nth-child(3){
          padding 0 0.15rem 0 0
          .cnts{
            .cnt1{
              font-size 0.8703rem
            }
          }
        }
        &:nth-child(4){
          padding 0 0 0 0.15rem
          justify-content flex-end
          .cnts{
            .dw-btn{
              font-size 0.4444rem
              padding 0.2rem .8rem
              text-align center
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 760px){
 .CityB2{
  .scenicspots{
      .fras{
        .spotcnts{
          .cnt-fra{
            .imgfra{
              height 10rem
              >img{
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="stylus">
.CityB2{
  .el-main {
    overflow: initial;
  }
  .el-popover .el-popover__title {
    height: 20px;
    font-size: 0.3rem;
    text-align: center;
    align-items: center;
    line-height: 34px;
    color: #6a6969;
  }
  .asaa-mia {
    position: relative;
  }
  .asaa .asaa-mia .ec-top-foots {
    position: absolute;
    top: -.78rem;
    left: 20px;
    background: rgba(0, 182, 205,1);
    min-width: 6.5rem;
    height: 0.78rem;
    align-items: center;
  }
  .asaa .asaa-mia .ec-top-foots .el-breadcrumb {
    line-height: 0.78rem;
    padding-left: 0.1851rem;
  }
  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__inner a {
    color: #fff;
    font-weight: 400;
  }
  .el-breadcrumb__item .el-breadcrumb__separator {
    color: #fff;
  }
  .el-breadcrumb__inner a:hover {
    color: #fff600;
  }
  @media screen and (max-width: 992px) {
    .asaa .asaa-mia .ec-top-foots {
      min-width auto
      padding-right 0.1851rem
    }
    .asaa .asaa-mia .el-breadcrumb{
      font-size 0.3703rem
    }
  }
}
</style>
