<template>
  <div class="CityB2">
    <el-container>
      <el-header style="padding: 0" height="100%">
        <vheader class="hidden-sm-and-down"></vheader>
        <ivheadera class="hidden-md-and-up"></ivheadera>
        <div class="top-img">
          <!-- <img class="hidden-sm-and-down" width="100%" src="../../assets/images/B/B2/topimg.jpg" alt="" /> -->
          <img class="hidden-md-and-up" width="100%" src="../../assets/images/B/B2/topimg-m.jpg" style="display: block;" alt="" />
          <div class="top-img-top">
            <p>JUST SHENZHEN</p>
            <span>正深圳生态路线图</span>
          </div>
        </div>
      </el-header>
      <el-main style="margin-top: 0" class="asaa">
        <div class="w asaa-mia">
          <div class="ec-top-foots hidden-sm-and-down">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item><router-link to="/JustSZ">体验深圳</router-link></el-breadcrumb-item>
              <el-breadcrumb-item>正深圳生态路线</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="introduce">
            <el-row type="flex" style="flex-wrap: wrap;align-items: flex-end;">
              <el-col :xs="24" :sm="24" :md="12">
                <div class="topcover"><img src="../../assets/images/B/B2/topcover.jpg" alt="" /></div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12">
                <div class="top-right">
                  <img src="../../assets/images/B/B2/topcovertit.png" />
                  <div class="cnts">
                    <p>
                      聊起深圳，相关的关键词有很多，比如经济、金融、科技、创新创业、现代、活力......这座平均年龄只有32岁的年轻城市的确是无数创业者和追梦人的乐土，但每天在这座城市穿梭的你，有没有注意到“深圳绿”？
                    </p>
                    <p>
                      作为一个建成区绿化覆盖率达到45.1%的“绿色之城”，深圳拥有超过950个花园，城市绿道总长达2400公里，全市绿色建筑总面积超过6000万平方米，“开窗见景，出门入园”是市民的生活常态！
                    </p>
                    <p>
                      在此，“正深圳 Just Shenzhen”城市体验线路特别从滨海湿地、公园环境、郊野森林、绿色建筑和海岸五个生态系统中挑选了12个最具代表性的地点，带你一起领略“深圳绿”。
                    </p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          </div>
          <div class="w">
          <!-- <div class="activelist">
            <div class="tit1 hidden-sm-and-down"><img src="../../assets/images/B/B2/recommend.png" /></div>
            <div class="funActive-card">
              <el-row>
                <h3 class="small" ref="funCur">
                  <el-col :xs="24" :sm="8" :md="8" v-for="(item, index1) in 3" :key="index1">
                    <div class="fcard-item">
                      <el-col :xs="12" :sm="24" :md="24">
                        <div class="over-hidden"><img class="item-img" width="100%" src="../../assets/images/B/B2/funcover.jpg" alt="" /></div>
                      </el-col>
                      <el-col :xs="12" :sm="24" :md="24">
                        <div class="fcard-content">
                          <div class="fcard-txt"><p>深圳生态地图体验官招募</p></div>
                          <div class="fcard-icon">
                            <p>
                              <i class="el-icon-time"></i>
                              <span>9月24日 00:00 - 9月30日 00:00</span>
                            </p>
                            <p>
                              <i class="el-icon-location"></i>
                              广东省深圳市-南山区华侨城湿地公园
                            </p>
                          </div>
                          <div class="fcard-foot clearfloat">
                            <img src="../../assets/images/B/B2/funlogo.jpg" alt="" />
                            <span class="display-none">cityplus</span>
                          </div>
                        </div>
                      </el-col>
                    </div>
                  </el-col>
                </h3>
              </el-row>
            </div>
          </div> -->
          </div>
          <div class="w">
          <div class="map">
            <img src="../../assets/images/B/B2/map.jpg" alt="" />
            <div class="map-cnts">
              <el-row type="flex">
                <el-col :xs="24" :sm="24" :md="12" class="left">
                  <div class="cnts">
                    <p class="hidden-sm-and-down">JUST</p>
                    <p class="hidden-sm-and-down">SHENZHEN</p>
                    <p class="cnts-m hidden-md-and-up">正深圳生态路线图</p>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" class="right">
                  <div class="cnts">
                    <a href="/static/download/ecology.jpg" class="dw-btn" target="_blank">下载地图</a>
                    <p class="hidden-sm-and-down">正深圳生态路线图</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="scenicspots">
            <el-row type="flex" class="fras">
              <el-col :xs="24" :sm="24" :md="8" class="spotcnts" v-for="(item, index) in datas" :key="index">
                <div class="cnt-fra">
                  <div class="imgfra">
                    <img :src="require(`../../assets/images/B/B2/mapimg${index}.png`)" />
                  </div>
                  <div class="cnts">
                    <p class="tit-zh">
                      <span>{{ index + 1 > 9 ? index + 1 : `0${index + 1}` }}</span>
                      {{ item.zhTit }}
                    </p>
                    <p class="tit-en">{{ item.enTit }}</p>
                    <p class="cnt1">
                      <img src="../../assets/images/B/B2/icon1.png" />
                      {{ item.cnt1 }}
                    </p>
                    <p class="cnt2">{{ item.cnt2 }}</p>
                    <p class="cnt3">
                      <img src="../../assets/images/B/B2/icon2.png" />
                      {{ item.cnt3 }}
                    </p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          </div>
        <div class="line"><img src="../../assets/images/B/B2/line.png" alt="" /></div>
        <div class="w">
          <div class="calendar">
            <img src="../../assets/images/B/B2/tit.png" alt="" class="tit hidden-sm-and-down" />
            <img src="../../assets/images/B/B2/tit-m.png" alt="" class="tit hidden-md-and-up" />
            <p class="tit-m hidden-md-and-up">左右滑动查看全部</p>
            <img src="../../assets/images/B/B2/cnt1.png" alt="" class="cnt hidden-sm-and-down" />
            <div class="img-m hidden-md-and-up">
              <img src="../../assets/images/B/B2/cnt1-m.jpg" alt="">
            </div>
            <img src="../../assets/images/B/B2/tit2.png" alt="" class="tit hidden-sm-and-down" />
            <img src="../../assets/images/B/B2/tit2-m.png" alt="" class="tit hidden-md-and-up" />
            <p class="tit-m hidden-md-and-up">左右滑动查看全部</p>
            <img src="../../assets/images/B/B2/cnt2.png" alt="" class="cnt hidden-sm-and-down" />
            <img src="../../assets/images/B/B2/cnt3.png" alt="" class="cnt hidden-sm-and-down" />
            <div class="img-m m2 hidden-md-and-up">
              <img src="../../assets/images/B/B2/cnt2-m.jpg" alt="">
            </div>
            <img src="../../assets/images/B/B2/cnt3-m.png" alt="" class="cnt hidden-md-and-up" />
          </div>
        </div>
        <div class="bom">
          <div class="w asaa-mia">
            <el-row type="flex" style="flex-wrap: wrap;">
              <el-col :xs="24" :sm="24" :md="12" class="bom-cnts">
                <el-col :xs="12" :sm="12" :md="12" class="fra hidden-sm-and-down"><img src="../../assets/images/B/B2/bommap1.png" alt="" /></el-col>
                <el-col :xs="12" :sm="12" :md="12" class="fra hidden-sm-and-down"><img src="../../assets/images/B/B2/bommap2.png" alt="" /></el-col>
                <el-col :xs="24" :sm="24" :md="24" class="fra hidden-sm-and-down" style="margin-top: 0.3333rem;">
                  <div class="bom-icon"><img src="../../assets/images/B/B2/bom-icon1.png" alt="" /></div>
                  <div class="bom-icon"><img src="../../assets/images/B/B2/bom-icon2.png" alt="" /></div>
                  <div class="bom-icon"><img src="../../assets/images/B/B2/bom-icon3.png" alt="" /></div>
                  <div class="bom-icon"><img src="../../assets/images/B/B2/bom-icon4.png" alt="" /></div>
                  <div class="bom-icon"><img src="../../assets/images/B/B2/bom-icon5.png" alt="" /></div>
                </el-col>
              </el-col>
              <el-col :xs="24" :sm="24" :md="8" class="fra hidden-sm-and-down">
                <div class="cnts">
                  <p class="cnt1">JUST</p>
                  <p class="cnt1">SHENZHEN</p>
                  <p class="cnt2">正深圳生态路线图</p>
                  <a href="/static/download/ecology.jpg" class="dw-btn" target="_blank">下载地图</a>
                </div>
              </el-col>
              <el-col :xs="14" :sm="14" :md="14" class="fra hidden-md-and-up">
                <div class="cnts">
                  <p class="cnt1">JUST</p>
                  <p class="cnt1">SHENZHEN</p>
                  <p class="cnt2">正深圳生态路线图</p>
                </div>
              </el-col>
              <el-col :xs="10" :sm="10" :md="10" class="fra hidden-md-and-up">
                <div class="cnts">
                  <a href="/static/download/ecology.jpg" class="dw-btn" target="_blank">下载地图</a>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-main>
      <gotop></gotop>
      <el-footer>
        <vfooter class="hidden-sm-and-down"></vfooter>
        <ivfooter class="hidden-md-and-up"></ivfooter>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import { datas } from '../../assets/js/datas.js'
export default {
  data () {
    return {
      datas: datas
    }
  },
  mounted() {
  }
}
</script>

<style lang="stylus" scoped>
.CityB2
  .el-main
    overflow initial
    padding 0
  .asaa .asaa-mia .header-title
    margin 0.6rem 0
  .w
    padding 0 0.3703rem
  .asaa .asaa-mia
    padding 1.5555rem 0.3703rem
  .asaa .asaa-mia,.w
    .introduce
      .topcover
        margin-right 5%
        img
          width 100%
      .top-right
        margin-left 5%
        text-align left
        >img
          margin-bottom 0.6481rem
        .cnts
          p
            color #595959
            font-size 0.2592rem
            text-indent 2em
            text-align justify
    .tit1
      text-align left
    .funActive-card
      text-align left
      margin-top 0.5rem
      margin-bottom 0.5rem
      margin-right -0.35rem
      .small
        display inline-block
        width 100%
      .fcard-item
        position relative
        display inline-block
        background #fff
        margin-right 0.3rem
        margin-bottom 0.3rem
        border 1px solid #D5D5D5
        // width 95%
        // border-radius: 5px;
        .over-hidden
          overflow hidden
          .item-img
            transition all 0.3s ease-out 0s
            cursor pointer
            display block
            height 3.7rem
            &:hover
              transform scale(1.1)
        .fcard-content
          padding 0 0.4rem
          .fcard-txt
            position relative
            color #474747
            font-size 0.3rem
            margin-left 0.15rem
            height 1rem
            display flex
            align-items center
            p
              padding-left 0.4rem
              position relative
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              &:before
                content ' '
                position absolute
                top 10%
                left 0
                height 80%
                border-right 3px solid #A6CE36
          .fcard-icon
            color #d3d3d3
            font-size 0.25rem
            margin-top 0.2rem
            margin-bottom 0.1rem
            p
              overflow hidden
              text-overflow ellipsis
              white-space nowrap
              i
                color #BFDA5D
                font-size 0.3rem
                margin-right 0.3rem
          .fcard-foot
            display flex
            align-items center
            padding 0.14rem 0
            border-top 1px solid #d3d3d3
            img, span
              float left
            img
              width 0.75rem
            span
              margin-left 0.3rem
  .map
    background #FFF6BE
    >img
      width 100%
      display block
    .map-cnts
      width 100%
      .left
        color #c8a063
        font-size 0.8888rem
        text-align right
        .cnts
          display inline-block
          text-align left
          padding 0 0.1851rem 0 0
          height 100%
          p
            font-family 'HYQiHeiY1-HEW'
            font-weight bold
            &.cnts-m{
              font-size 0.5185rem
              font-family auto
              font-weight 100
            }
      .right
        text-align left
        .cnts
          text-align right
          display inline-block
          padding 0 0 0 0.1851rem
          height 100%
          .dw-btn
            display inline-block
            color #fff
            font-size 0.2962rem
            background #ffa200
            border-radius 0.2rem
            padding 0.2rem 1rem
            margin 0.3rem 0 0.4rem 0
            cursor pointer
            transition all .2s
            &:hover{
              background #ffc000
            }
          p
            color #c8a063
            font-size 0.6296rem
  .scenicspots
    .fras
      flex-wrap wrap
      .spotcnts
        border-top 1px dashed #917035
        padding 2% 0
        &:nth-child(1){
          border-top none
        }
        &:nth-child(2){
          border-top none
        }
        &:nth-child(3){
          border-top none
        }
        &:nth-child(3n+3)
          .cnt-fra
            border none
        &:nth-last-child(1){
          .cnt-fra{
            .cnts{
              .cnt2{
                min-height 2rem
              }
            }
          }
        }
        &:nth-last-child(2){
          .cnt-fra{
            .cnts{
              .cnt2{
                min-height 2rem
              }
            }
          }
        }
        &:nth-last-child(3){
          .cnt-fra{
            .cnts{
              .cnt2{
                min-height 2rem
              }
            }
          }
        }
        .cnt-fra
          padding 0 4%
          border-right 1px dashed #917035
          display flex
          flex-wrap wrap
          height 100%
          flex-direction column
          .imgfra
            height 8rem
            display flex
            align-items center
            justify-content center
            >img
              display block
              margin 0 auto
              width 90%
          .cnts
            text-align justify
            align-self flex-end
            img
              margin 0.05rem 0.1rem 0 0
            .tit-zh
              color #a47841
              font-size 0.4444rem
              font-weight bold
              padding 0.4rem 0 0 0
              line-height 0.8
              span
                margin 0 0.06rem 0 0
            .tit-en
              color #a47841
              font-size 0.2592rem
              padding-left 0.74rem
              font-weight bold
            .cnt1
              color #44ab6f
              font-size 0.2592rem
              padding 0.1rem 0 0.1rem 0.74rem
              display flex
              align-items flex-start
              min-height .6rem
            .cnt2
              color #a47841
              font-size 0.2592rem
              padding 0.1rem 0 0.1rem 0.74rem
              display flex
              min-height 2.4rem
            .cnt3
              color #a47841
              font-size 0.2592rem
              padding 0.1rem 0 0.1rem 0.74rem
              display flex
              align-items flex-start
  .line
    text-align right
    img
      width 70%
  .calendar
    text-align left
    padding-bottom .5rem
    .tit
      padding 1.1666rem 0 0.5rem 0
    .cnt
      width 100%
  .bom
    width 100%
    background #c8a063
    color #fff
    .bom-cnts{
      .bom-icon{
        width 20%
        margin-right .1rem
        display inline-block
        height 4rem
        &:last-child{
          margin-right 0
        }
        img{
          width 100%
          height 100%
          object-fit cover
          border 2px solid #fff
        }
      }
    }
    .fra
      text-align left
      padding 0 0.3703rem
      display flex
      align-items center
      img
        width 100%
      .cnts
        .cnt1
          font-size 0.8888rem
          font-weight bold
          line-height 1.3
          font-family 'HYQiHeiY1-HEW'
          font-weight bold
        .cnt2
          font-size 0.6296rem
          line-height 1.3
        .dw-btn
          display inline-block
          color #fff
          font-size 0.2962rem
          background #ffa200
          border-radius 0.2rem
          padding 0.2rem 1rem
          margin 0.3rem 0 0 0
          cursor pointer
          transition all .2s
          &:hover{
            background #ffc000
          }
  .top-img
    position relative
    background url('../../assets/images/B/B2/topimg.jpg') no-repeat center
    height 7.1666rem
    img
      display block
    .top-img-top
      position absolute
      top 45%
      left 5%
      color #FFFFFF
      text-align left
      p
        font-size 0.6rem
        font-weight 600
        font-family 'HYQiHeiY1-HEW'
      span
        font-size 0.45rem
        font-weight 300
      &:after
        content ''
        position absolute
        left 0
        top -16px
        height 1px
        width 40%
        background #FFF
@media screen and (max-width: 990px){
  .CityB2{
    .top-img{
      height auto
    }
    .w{
      .funActive-card{
        .fcard-item{
          display flex
          justify-content center
          align-items center
          margin-top 0.3rem
          .over-hidden{
            .item-img{
              height 2.5rem
            }
          }
          .fcard-content{
            position relative
            .fcard-icon{
              p{
                overflow hidden
                text-overflow ellipsis
                white-space nowrap
                max-width 3rem
              }
            }
            .fcard-foot{
              padding 0
              border none
              img{
                position absolute
                right 6%
                bottom 5%
              }
              span{
                display none
              }
            }
          }
        }
      }
    }
    .asaa{
      .asaa-mia{
        padding 0.54rem 0.3703rem
        .introduce{
          .topcover{
            margin 0
          }
          .top-right{
            margin 0
            >img{
              width 50%
              margin-bottom 0.4rem
              margin-top 0.4rem
            }
            .cnts{
              p{
                font-size 0.3703rem
              }
            }
          }
        }
        .tit1{
          img{
            width 50%
          }
        }
      }
    }
    .map{
      .map-cnts{
        padding .4rem 0
        .left{
          .cnts{
            display flex
            align-items center
            justify-content flex-end
          }
        }
        .right{
          display block
          .cnts{
            .dw-btn{
              margin 0
              font-size 0.4444rem
            }
          }
        }
      }
    }
    .scenicspots{
      .fras{
        .spotcnts{
          padding 4% 0
          &:nth-child(2){
            border-top 1px dashed #917035
          }
          &:nth-child(3){
            border-top 1px dashed #917035
          }
          .cnt-fra{
            border none
            .imgfra{
              height 14rem
              >img{
              }
            }
            .cnts{
              .tit-zh{
                font-size 0.4444rem
              }
              .tit-en{
                font-size 0.3703rem
              }
              .cnt1{
                font-size 0.3333rem
              }
              .cnt2{
                font-size 0.3703rem
              }
              .cnt3{
                font-size 0.3333rem
              }
              img{
                width 4%
                margin-top .07rem
              }
            }
          }
        }
      }
    }
    .line{
      img{
        width 100%
        padding .4rem 0
      }
    }
    .calendar{
      .tit{
        width 45%
        vertical-align middle
      }
      .tit-m{
        width 55%
        display inline-block
        padding 1.1666rem 0 0.5rem 0
        color #a47841
        font-size 0.4444rem
        vertical-align middle
        text-align right
        font-weight bold
      }
      .img-m{
        width 100%
        height 10.4rem
        overflow-x auto
        -webkit-overflow-scrolling touch
        padding-bottom .4rem
        &::-webkit-scrollbar{
          height .2rem
          border-radius .2rem
          width 100%
        }
        &::-webkit-scrollbar-track-piece{
          background #dcdcdc
          border-radius .2rem
        }
        &::-webkit-scrollbar-thumb{
          background #a47741
          border-radius .2rem
        }
        img{
          height 100%
          display block
        }
        &.m2{
          height 16.4rem
        }
      }
    }
    .bom{
      .fra{
        &:nth-child(3){
          padding 0 0.15rem 0 0
          .cnts{
            .cnt1{
              font-size 0.8703rem
            }
          }
        }
        &:nth-child(4){
          padding 0 0 0 0.15rem
          justify-content flex-end
          .cnts{
            .dw-btn{
              font-size 0.4444rem
              padding 0.2rem .8rem
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 760px){
  .CityB2{
   .scenicspots{
      .fras{
        .spotcnts{
          .cnt-fra{
            .imgfra{
              height 10rem
              >img{
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="stylus">
.CityB2{
  .el-main {
    overflow: initial;
  }
  .el-popover .el-popover__title {
    height: 20px;
    font-size: 0.3rem;
    text-align: center;
    align-items: center;
    line-height: 34px;
    color: #6a6969;
  }
  .asaa-mia {
    position: relative;
  }
  .asaa .asaa-mia .ec-top-foots {
    position: absolute;
    top: -.78rem;
    left: 20px;
    background: rgba(0, 182, 205,1);
    min-width: 6.5rem;
    height: 0.78rem;
    align-items: center;
  }
  .asaa .asaa-mia .ec-top-foots .el-breadcrumb {
    line-height: 0.78rem;
    padding-left: 0.1851rem;
  }
  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__inner a {
    color: #fff;
    font-weight: 400;
  }
  .el-breadcrumb__item .el-breadcrumb__separator {
    color: #fff;
  }
  .el-breadcrumb__inner a:hover {
    color: #fff600;
  }
  @media screen and (max-width: 992px) {
    .asaa .asaa-mia .ec-top-foots {
      min-width auto
      padding-right 0.1851rem
    }
    .asaa .asaa-mia .el-breadcrumb{
      font-size 0.3703rem
    }
  }
}
</style>
