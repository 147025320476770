<template>
  <div class="cityB4">
    <el-header style="padding: 0" height="100%">
      <vheader class="hidden-sm-and-down"></vheader>
      <ivheadera class="hidden-md-and-up"></ivheadera>
    </el-header>
    <iframe
      id="frameId"
      class="b4fra"
      src="/static/zh/sz_line.html"
      frameborder="0"
      scrolling="no"
      marginheight="0" marginwidth="0"
      onLoad="this.style.height=Math.max(this.contentWindow.document.body.scrollHeight,this.contentWindow.document.documentElement.scrollHeight,200)+'px'"
      @load="learmask()"
      ></iframe>
    <el-footer>
      <vfooter class="hidden-sm-and-down"></vfooter>
      <ivfooter class="hidden-md-and-up"></ivfooter>
    </el-footer>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  methods: {
    learmask () {
      let frame = document.getElementById('frameId').contentWindow
      let _index = 0
      frame.addEventListener('click', e => {
        let mask = frame.$('.wap_tag_bg')
        if(frame.$('.wap_show_tag:visible').length != 0){
          _index = frame.$('.wap_show_tag:visible').index() + 1
        }
        if (mask.css('display') == 'block') {
          window.scrollTo(0, 0)
          $('body').css({
            'width': '100%',
            'height': '100%',
            'overflow': 'hidden'
          })
        } else {
          let _classTop = frame.$('.wap_ac0' + _index).offset().top
          $('body').css({
            'width': 'auto',
            'height': 'auto',
            'overflow': 'auto'
          })
          $('html,body').animate({
            'scrollTop': _classTop
          }, 200)
        }
      })
    },
    addAni (obj, cls, time) {
      setTimeout(() => {
        this.getFrameDiv(obj).addClass(cls)
      }, time)
    },
    getFrameDiv (obj) {
      let value = document.getElementById('frameId').contentWindow.$(obj)
      return value
    },
    scrollListener () {
      let scrollTop = $(window).scrollTop()
      this.$nextTick(() => {
        if (scrollTop > 1000) {
          this.getFrameDiv('.ac03').addClass('bounceInLeft oc')
          this.getFrameDiv('.ac04').addClass('bounceInRight oc')
          this.addAni('.tag03', 'zoomIn oc', 1300)
          this.addAni('.tag04', 'zoomIn oc', 1300)
        } else {
          this.getFrameDiv('.ac03').removeClass('bounceInLeft oc')
          this.getFrameDiv('.ac04').removeClass('bounceInRight oc')
          this.getFrameDiv('.tag03').removeClass('zoomIn oc')
          this.getFrameDiv('.tag04').removeClass('zoomIn oc')
        }
        if (scrollTop > 2000) {
          this.getFrameDiv('.ac05').addClass('bounceInLeft oc')
          this.getFrameDiv('.ac06').addClass('bounceInRight oc')
          this.addAni('.tag05', 'zoomIn oc', 1300)
          this.addAni('.tag06', 'zoomIn oc', 1300)
        } else {
          this.getFrameDiv('.ac05').removeClass('bounceInLeft oc')
          this.getFrameDiv('.ac06').removeClass('bounceInRight oc')
          this.getFrameDiv('.tag05').removeClass('zoomIn oc')
          this.getFrameDiv('.tag06').removeClass('zoomIn oc')
        }
        if (scrollTop > 3000) {
          this.getFrameDiv('.ac07').addClass('bounceInLeft oc')
          this.getFrameDiv('.ac08').addClass('bounceInRight oc')
          this.addAni('.tag07', 'zoomIn oc', 1300)
          this.addAni('.tag08', 'zoomIn oc', 1300)
        } else {
          this.getFrameDiv('.ac07').removeClass('bounceInLeft oc')
          this.getFrameDiv('.ac08').removeClass('bounceInRight oc')
          this.getFrameDiv('.tag07').removeClass('zoomIn oc')
          this.getFrameDiv('.tag08').removeClass('zoomIn oc')
        }
      })
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollListener)
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollListener)
    document.getElementById('frameId').contentWindow.removeEventListener('click')
  }
}
</script>

<style lang="less" scoped>
.cityB4 {
  .b4fra {
    display: block;
    width: 100%;
  }
}
</style>
